import { API_URLS } from 'CONFIG/API_URLS';
import { PARTICIPANT_TYPE } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { GET_PARTICIPANT_INFO } from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';

export const getParticipantInfo = (type, id, userData) => (dispatch) => {
    let url = API_URLS.COMPANY_BY_ID(id);
    if (type === PARTICIPANT_TYPE.personal) {
        url = API_URLS.USER_BY_ID(id);
    }
    if (checkNested(userData, 'id') === id) {
        const name = `${userData.fname || ''} ${userData.lname || ''}`;
        const payload = { name, avatar: userData.avatar, id: userData.id };
        dispatch(setIsFetching([GET_PARTICIPANT_INFO, `${type}_${id}`], false));
        return dispatch({
            type: GET_PARTICIPANT_INFO,
            payload,
            participantType: type
        });
    }
    dispatch(setIsFetching([GET_PARTICIPANT_INFO, `${type}_${id}`], true));
    dispatch(apiAction(
        {
            url,
            auth: false,
            onSuccess: data => () => {
                const res = data.content;
                let userName = `${res.fname || ''} ${res.lname || ''}`;
                if (!res.fname && !res.lname) {
                    userName = `${res.pseudonym}*****`;
                }
                const name = type === PARTICIPANT_TYPE.personal ? userName : res.name;
                const payload = { contacts: res.userContact, name, avatar: data.content.avatar, id: res.id, status: res.status };
                dispatch({
                    type: GET_PARTICIPANT_INFO,
                    payload,
                    participantType: type
                });
                dispatch(setIsFetching([GET_PARTICIPANT_INFO, `${type}_${id}`], false));
            },
            label: GET_PARTICIPANT_INFO
        }
    ));
};
