import { API, SAVE_ROUTE_TO_CACHE } from 'ACTIONS';
import { apiAccess, apiError, apiStart, apiEnd } from 'ACTIONS/api';
import Storage from 'HOC/storage';
import { setAccessToken } from 'MODULES/api';

import omit from 'lodash/omit';
import { OAUTH_PARAMS, OAUTH_URL, ROUTE_TTL, STORAGE } from 'CONFIG';
import { buildMessagingApiUrl } from 'MODULES/buildMessagingApiUrl';
import { checkNested } from 'MODULES/checkNested';
import { buildApiUrl } from 'MODULES/buildApiUrl';
import { showErrorMsg } from 'ACTIONS/ui/showErrorMsg';
import forEach from 'lodash/forEach';
import qs from 'qs';


const fetchData = (url, params) => new Promise(async (res) => {
    try {
        const result = await fetch(url, params);
        res(result);
    } catch (e) {
        res(e);
    }
});

const returnHeaders = (headers) => {
    let h = {};
    forEach(headers, (hv) => {
        h = { ...h, [hv[0]]: hv[1] };
    });

    return h;
};

const apiMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (!action) {
        return;
    }

    if (typeof action === 'function') {
        return action(dispatch, getState);
    }

    if (!action.type) {
        return false;
    }

    next(action);

    if (action.type !== API) return;


    // routerMiddleware(history)

    const {
        responseType,
        url,
        method,
        data,
        host,
        onSuccess,
        onFailure,
        formError,
        label,
        notificationLabel,
        chained,
        reject,
        resolve,
        msg,
        notificationFunction,
        headers,
        returnParams,
        apiPrefix,
        withCredentials,
        successOnError,
        auth,
        body
    } = action.payload;

    const dataOrParams = ['GET', 'DELETE'].includes(method)
        ? 'data' : 'body';
    if (label) {
        dispatch(apiStart(label));
    }
    let authToken = checkNested(Storage.get('tokensShort', true), 'access') || checkNested(headers, 'Authorization');
    if (authToken && withCredentials && auth) {
        authToken = { Authorization: `Bearer ${authToken}` };
    } else {
        authToken = {};
    }
    let nonAuthHeaders = { 'Content-Type': 'application/json', ...omit(headers, ['Authorization', 'refresh']) };

    if (headers['Content-Type'] === false) {
        nonAuthHeaders = omit(nonAuthHeaders, 'Content-Type');
    }


    let requestUrl = msg ? buildMessagingApiUrl(url, '', getState) : buildApiUrl(url, '', getState, host, apiPrefix);
    if (method === 'GET' && data) {
        requestUrl = `${requestUrl}${qs.stringify(data, { addQueryPrefix: true })}`;
    }
    fetchData(requestUrl, { method, headers: { ...nonAuthHeaders, ...authToken }, [dataOrParams]: data ? JSON.stringify(data) : body })
        .then((r) => new Promise(async (resolveFetch) => {
            if (r.status === 401 && authToken.Authorization) {
                const oldToken = authToken.Authorization.replace('Bearer ', '');
                if (!checkNested(window, ['failedTokens', oldToken])) {
                    window.failedTokens = { ...checkNested(window, 'failedTokens', {}), [oldToken]: true };
                    const refreshToken = checkNested(Storage.get('tokensLong', true), 'refresh');
                    if (refreshToken) {
                        const tokensFetch = fetchData(OAUTH_URL, {
                            method: 'POST',
                            body: new URLSearchParams({ ...OAUTH_PARAMS({ grant_type: 'refresh_token' }), ...{ refresh_token: refreshToken } }),
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        });

                        tokensFetch.then(async (tokensPayload) => {
                            try {
                                const tokensNew = await tokensPayload.json();
                                setAccessToken(tokensNew);
                            } catch (e) {
                                setAccessToken({});
                                Storage.set(STORAGE.login, 0);
                                window.location.reload();
                            }
                        });
                    } else {
                        setAccessToken({});
                        Storage.set(STORAGE.login, 0);
                        window.location.reload();
                    }
                }

                const newInterval = setInterval(async () => {
                    const cookieToken = checkNested(Storage.get('tokensShort', true), 'access');
                    if (cookieToken !== oldToken) {
                        clearInterval(newInterval);
                        const newAuthToken = { Authorization: `Bearer ${cookieToken}` };
                        const retriedFetch = await fetchData(requestUrl, { method, headers: { ...nonAuthHeaders, ...newAuthToken }, [dataOrParams]: data ? JSON.stringify(data) : body });
                        const retriedFetchJson = retriedFetch.status < 300 ? await retriedFetch.text() : '';

                        resolveFetch({ responseData: retriedFetchJson ? JSON.parse(retriedFetchJson) : retriedFetchJson, responseHeaders: returnHeaders([...checkNested(retriedFetch, 'headers', [])]) });
                    }
                }, 100);
            } else if (r.status < 300) {
                if (responseType === 'blob') {
                    const d = await r.blob();
                    resolveFetch({ responseData: d, responseHeaders: returnHeaders([...r.headers]) });
                } else {
                    const d = await r.text();
                    resolveFetch({ responseData: d ? JSON.parse(d) : d, responseHeaders: returnHeaders([...r.headers]) });
                }
            } else if (r.status >= 400) {
                const d = await r.text();
                resolveFetch({ responseData: d ? JSON.parse(d) : d, responseHeaders: returnHeaders([...r.headers]) });
            }
        }))
        .then((responseObject) => {
            const { responseData, responseHeaders, status } = responseObject;
            const res = responseData;
            if (status === 404) {
                throw new Exception({ status, response: res });
            } else {
                const data = res;
                if (data && (!data.error || successOnError)) {
                    resolve(data);
                    dispatch({
                        type: SAVE_ROUTE_TO_CACHE,
                        payload: { route: url, ttl: new Date().getTime() + ROUTE_TTL }
                    });
                    dispatch(onSuccess(data.data ? { content: data } : data, responseHeaders, returnParams, res));
                }
                if (!data) {
                    dispatch(onSuccess({}, responseHeaders));
                }
                if (data && data.notification) {
                    dispatch(notificationFunction(notificationLabel || label, data));
                }
                if (data && data.error && !successOnError) {
                    dispatch(showErrorMsg(notificationLabel || label, checkNested(data, 'error', [])));
                    dispatch(onFailure(data));
                    if (formError) {
                        reject(checkNested(data, 'error', []));
                    }
                }
            }
        })

        .catch((error) => {
            dispatch(showErrorMsg(notificationLabel || label, checkNested(error.response, 'data.error', [])));
            dispatch(apiError(error));
            if (formError) {
                reject(error);
            } else {
                resolve();
            }

            dispatch(onFailure(checkNested(error, 'response.data'), checkNested(error.response, 'status')));

            if (error.response && error.response.status === 403) {
                dispatch(apiAccess(window.location.pathname));
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label, chained));
            }
        });
};

export default apiMiddleware;

