import { createSelector } from 'reselect';
import get from 'lodash/get';

import { checkNested } from 'MODULES/checkNested';
import { CONTACT_TYPES } from 'CONST';
import values from 'lodash/values';


export const getLoginData = createSelector(
    [state => state.user.signInStatus],
    signInStatus => (signInStatus)
);


export const getUserCookie = createSelector(
    [state => state.user.signInStatus],
    signInStatus => get(signInStatus, 'cookie', false)
);

export const isUserLoggedIn = createSelector(
    [getUserCookie],
    isLoggedIn => (isLoggedIn)
);

export const getUserContactsState = createSelector(
    [state => state.user.contacts],
    contacts => contacts
);

export const getUserPendingContactsState = createSelector(
    [state => state.user.contacts],
    contacts => checkNested(contacts, 'pending')
);

export const getUserVerifiedContactsState = createSelector(
    [state => state.user.contacts],
    contacts => checkNested(contacts, 'verified')
);


export const getUserVerifiedPhone = createSelector(
    getUserVerifiedContactsState,
    contacts => checkNested(values(checkNested(contacts, [CONTACT_TYPES.PHONE])), 0)
);

export const getUserData = createSelector(
    [state => state.user.userData],
    userData => userData
);

export const getUserDataState = createSelector(
    state => state.user.userData,
    userData => userData
);

export const getUserCompanyId = createSelector(
    [getUserDataState],
    userData => checkNested(userData, 'company.id')
);


export const getUserIDSelector = createSelector(
    [getUserDataState],
    userData => checkNested(userData, 'id')
);



export const userOffersSelector = createSelector(
    [state => state.user.offers],
    offers => offers
);

export const getWalletData = createSelector(
    [state => state.user.wallet],
    wallet => wallet
);
export const getWalletLogData = createSelector(
    [state => state.user.logs],
    logs => logs
);
