import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import values from 'lodash/values';
import { bindActionCreators } from 'redux';
import {
    GET_PPS_CATEGORY,
    GET_USER_AVATAR,
    SEARCH_QUERY,
    SET_PP_DATA
} from 'ACTIONS';
import { FILTER_HASH_LENGTH, SEARCH_TYPES, UI } from 'CONST';
import {
    getActivePage, getAllPp,
    getCurrentCategoryFromLink,
    getRootCategoriesSelector
} from 'MODULES/reselect/selectors/categories';
import { classifiedsPathByUrl } from 'MODULES/reselect/selectors/classified';
import { mSelectState } from 'MODULES/reselect/state';
import { fetchingSelector } from 'MODULES/reselect/selectors/ui';
import { getSelectedLanguage, isMessagingOn } from 'MODULES/reselect/selectors/general';
import { geFilterSubscriptionLots } from 'MODULES/reselect/selectors/filterSubscribtions';
import { validateHash } from 'MODULES';
import { stringExistsIn } from 'MODULES/validations';
import { getParent } from 'MODULES/getParent';
import { checkNested } from 'MODULES/checkNested';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import { deletePp } from 'ACTIONS/categories/deletePp';
import { changeLotStatus } from 'ACTIONS/create_classified/changeLotStatus';
import { patchFilterSubscriptionLotAsSeen } from 'ACTIONS/filterSubscriptions/patchFilterSubscriptionLotAsSeen';
import { switchLang } from 'ACTIONS/general/switchLang';
import { clearAutocomplete } from 'ACTIONS/search/clearAutocomplete';
import { searchByQuery } from 'ACTIONS/search/searchByQuery';
import { setSearchSuggestion } from 'ACTIONS/search/setSearchSuggestion';
import { setSearchTerm } from 'ACTIONS/search/setSearchTerm';
import { toggleShowAutocomplete } from 'ACTIONS/search/toggleShowAutocomplete';
import { setIsToggled } from 'ACTIONS/ui/setIsToggled';
import { showModalWithData } from 'ACTIONS/ui/showModalWithData';
import { addMSelectItem } from 'ACTIONS/ui/addMSelectItem';
import { emptyMSelect } from 'ACTIONS/ui/emptyMSelect';
import { sendLangToApi } from 'ACTIONS/user/sendLangToApi';

const searchState = state => state.search;
const bookmarkCountState = state => state.bookmarks.bookmarkCount;
const unseenFiltersState = state => state.filterSubscriptions.unseenFilters;
const unreadMessagesState = state => state.messaging.unreadMessages;
const totalMessagesState = state => state.messaging.totalMessages;
const userLotsState = state => state.user.userLots;
const searchActivated = state => state.ui.activateSearch;
const userDataState = state => state.user.userData;
const signInState = state => state.user.signInStatus;
const searchIsFetching = state => state.ui.isFetching[SEARCH_QUERY][SEARCH_TYPES.combined];
const avatarIsFetching = state => state.ui.isFetching[GET_USER_AVATAR];
const menuIsToggled = state => state.ui.isToggled[UI.RIGHT_MENU];
const companyLotsState = state => state.company.companyLotsAmount;
const routeSearchQuery = (state, props) => {
    const match = matchPath(props.location.pathname, {
        path: '/:lang/search/:searchQuery',
        exact: true,
        strict: false
    });
    return match ? match.params.searchQuery : null;
};


const getSearchData = (search, isFetching, routeParam, searchActivated, category, categoriesRoot, language) => {
    const searchResultByLink = search.searchResults.combined[search.searchQuery[SEARCH_TYPES.combined]];
    const searchSuggestionQuery = search.searchQuery[SEARCH_TYPES.suggestion];
    const searchSuggestion = search.searchResults[SEARCH_TYPES.suggestion][searchSuggestionQuery];
    const lots = checkNested(searchResultByLink, 'lots', []);
    const regionsToShow = [];
    const suggestionToShow = [];
    if (!isEmpty(searchSuggestion)) {
        forEach(searchSuggestion, suggestion => suggestionToShow.push({ ...suggestionToShow, type: 'suggestion', name: suggestion }));
    }

    const regions = checkNested(searchResultByLink, 'regions', []);
    forEach(regions, (region) => {
        const realEstateRoot = categoriesRoot[CATEGORIES.realEstate];
        if (!realEstateRoot) {
            return regionsToShow.push({ ...region, isLoadingCategories: true });
        }
        const parentTree = getParent(region.data, true);
        return forEach(realEstateRoot.children, cat => (
            regionsToShow.push({ ...region, rootCategoryId: cat.id, regionId: region.id, slug: cat.slug[language], id: `${cat.id}_${region.id}`, label: `${checkNested(cat, ['name', language]) || cat.name} | ${map(parentTree, r => r.name).join(' | ')}` })
        ));
    });

    const categories = checkNested(searchResultByLink, 'categories', []);
    const searchResults = size(lots) || size(categories) || size(regions)
        ? [...suggestionToShow, ...regionsToShow, ...categories, ...lots].map(o => (
            { ...o, name: o.preview || o.name })) : [];

    return ({
        category,
        showAutocomplete: search.showAutocomplete,
        searchResults,
        searchIsFetching: isFetching,
        totalCount: search.totalCount,
        searchQuery: search.searchQuery[SEARCH_TYPES.combined],
        searchRouteParam: routeParam,
        searchActivated
    });
};

const getHeaderRight = (bookmarkCount, userData, signInStatus, isFetching, isToggled, unseenFilters, unreadMessages, userLots, totalMessages, companyLotsAmount, isMessagingOn) => {
    const showMessaging = (checkNested(totalMessages, 'company') || checkNested(totalMessages, 'personal') || unreadMessages > 0) && isMessagingOn;
    return {
        bookmarkCount,
        userData,
        isLoggedIn: signInStatus.cookie,
        avatarIsFetching: isFetching,
        menuIsToggled: isToggled,
        unseenFilters,
        unreadMessages,
        userLots,
        companyLotsAmount,
        showMessaging
    };
};

export const rightReselect = createSelector(bookmarkCountState, userDataState, signInState, avatarIsFetching, menuIsToggled, unseenFiltersState, unreadMessagesState, userLotsState, totalMessagesState, companyLotsState, isMessagingOn, getHeaderRight);

export const searchDataReselect = createSelector(searchState, searchIsFetching, routeSearchQuery, searchActivated, getCurrentCategoryFromLink, getRootCategoriesSelector, getSelectedLanguage, getSearchData);


export const getMSelect = createSelector(
    [mSelectState, getAllPp, classifiedsPathByUrl, getActivePage, fetchingSelector(GET_PPS_CATEGORY), geFilterSubscriptionLots],
    (mSeleect, list, path, activePage, cat, filtersLots) => {
        let showDelete = true;
        let showClose = true;
        let showPublish = true;
        let pp = {};
        const hash = size(path) > 1 && stringExistsIn('/my/filters', path[1]) ? validateHash(path[1], FILTER_HASH_LENGTH, true) : false;
        const unseenFilterLots = checkNested(filtersLots, [hash]);
        values(mSeleect).forEach((i) => {
            pp = list[i.id];
            if (pp) {
                if (pp.xmlImported) {
                    showDelete = false;
                    showClose = false;
                }
                if (pp.status !== LOT_VISIBILITY.blocked.id && pp.status !== LOT_VISIBILITY.closed.id) {
                    showDelete = false;
                }
                if (pp.status === LOT_VISIBILITY.published.id) {
                    showPublish = false;
                }

                if (pp.status === LOT_VISIBILITY.closed.id || pp.status === LOT_VISIBILITY.blocked.id) {
                    showClose = false;
                }

                if (pp.status === LOT_VISIBILITY.pending_publish.id) {
                    showPublish = false;
                }

                if (pp.status === LOT_VISIBILITY.blocked.id) {
                    showPublish = false;
                }
            }
        });
        return {
            itemsSelected: mSeleect,
            showDelete,
            activePage,
            showClose,
            showPublish,
            path,
            pp,
            hash,
            unseenFilterLots
        };
    }
);

export const mapStateToProps = (state, props) => ({
    searchData: searchDataReselect(state, props),
    headerRight: rightReselect(state),
    isMobile: state.ui.isMobile,
    lang: state.general.selectedLang,
    isFetchingLotEdit: state.ui.isFetching[SET_PP_DATA],
    mSelect: getMSelect(state, props),
    searchActivated: state.ui.activateSearchInput
});

export const mapDispatchToProps = dispatch => ({
    functions: bindActionCreators({
        showModalWithData,
        searchByQuery,
        setSearchTerm,
        clearAutocomplete,
        setIsToggled,
        toggleShowAutocomplete,
        emptyMSelect,
        deletePp,
        switchLang,
        sendLangToApi,
        changeLotStatus,
        setSearchSuggestion,
        patchFilterSubscriptionLotAsSeen,
        addMSelectItem
    }, dispatch)
});

