import React, { useEffect } from 'react';
import last from 'lodash/last';
import map from 'lodash/map';
import values from 'lodash/values';
import { connect } from 'react-redux';
import { getCategoriesById } from 'MODULES/reselect/selectors/categories';
import { CATEGORIES_WITH_NEW_LANDING } from 'CONFIG';
import CategoryIcon from 'COMPONENTS/breadcrumb/category-select/select/icon';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { EXCLUDED_LANDING_FOR_CATEGORY } from 'CONST';
import { getParent } from 'MODULES/getParent';
import { checkNested } from 'MODULES/checkNested';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { getCategoryData } from 'ACTIONS/categories/getCategoryData';

const CategorySelect = ({
    i18n,
    history,
    lang,
    currentCategory,
    amount,
    isFetchingAmount,
    categories,
    pp
}) => {
    useEffect(() => {
        // getCategoryData(checkNested(currentCategory, 'parents.0.id', false, true), false, true);
        const breadcrumbElement = document.getElementById('breadcrumb-scroll');
        if (breadcrumbElement) breadcrumbElement.scrollLeft += 500;
    }, []);

    const parents = currentCategory.parent && checkNested(last(checkNested(currentCategory, 'parents', [currentCategory])), 'level') < 10 ? getParent(currentCategory, true, false, true) : checkNested(currentCategory, 'parents', [currentCategory]);
    const parentLength = checkNested(parents, 'length');
    const categoryName = checkNested(parents, [parentLength - 1, 'name', lang], checkNested(currentCategory, ['name', lang], checkNested(currentCategory, ['name'], '')));
    const currentCategoryLevel = checkNested(currentCategory, 'level', 0);
    const isRealEstate = parents[0].id === CATEGORIES.realEstate;
    const isCharity = parents[0].id === CATEGORIES.charity;

    return isMobile ? (
        <h3 className="title-wrapper">
            <select
                key="select"
                className="root-category-select"
                aria-label={i18n.__('Select category')}
                onClick={() => { }}
                value="disabled"
                onChange={(e) => {
                    const slug = e.target.value;
                    if (!slug || slug === 'disabled') {
                        return null;
                    }
                    if (slug === 'to-home') {
                        return history.push('/');
                    }

                    const { dataset } = e.target.options[e.target.selectedIndex];

                    history.push({ pathname: slug, search: dataset.search });
                }}
            >
                <option
                    style={{ display: 'none' }}
                    disabled
                    selected
                    value="disabled"
                />
                {map(values(parents).reverse(), p => (
                    p.slug && (
                        <option
                            data-search={p.search}
                            value={checkNested(categories[p.id], 'parents', {}).length > 1 && checkNested(categories[p.id], 'level', 0) === currentCategoryLevel && !isRealEstate
                                ? PP_URLS.CATEGORY(p.slug[lang], lang)
                                : ((p.level === 0 && !isCharity) || (isRealEstate && !p.search))
                                    ? PP_URLS.LANDING(lang, p.regionSlug ? `${p.regionSlug[lang]}` : p.slug[lang])
                                    : PP_URLS.CATEGORY(p.regionSlug ? `${p.regionSlug[lang]}` : p.slug[lang], lang)}
                            key={p.id}
                        >

                            {checkNested(p.name, lang) ? p.name[lang] : p.name}
                        </option>
                    )
                ))}
                <option value="to-home">{i18n.__('To home')}</option>
            </select>
            <CategoryIcon
                key="category"
                currentCategory={currentCategory}
                amount={amount}
                isFetchingAmount={isFetchingAmount}
                categoryName={categoryName}
            />
        </h3>
    ) : (
        <div className="breadcrumb-category">
            <CategoryIcon
                key="category"
                currentCategory={currentCategory}
                amount={amount}
                isFetchingAmount={isFetchingAmount}
            />
            <ul
                id="breadcrumb-scroll"
                key="select"
                className="w-100 breadcrumb-category__select"
            >
                <li>
                    <Link
                        value="to-home"
                        className="breadcrumb-category__first"
                        to={PP_URLS.HOME_LANG(lang)}
                        aria-label={i18n.__('To home')}
                    >
                        {i18n.__('To home')}
                    </Link>
                </li>
                {map(values(parents), (p, index) => (
                    p.slug && (
                        <li key={p.id}>
                            {index !== values(parents).length - 1 || (index === values(parents).length - 1 && pp) ? (
                                <Link
                                    to={
                                        ({
                                            search: p.search,
                                            pathname: (checkNested(categories[p.id], 'parents', {}).length > 1
                                                    || getParent(categories[p.id], true).length > 1
                                            ) && checkNested(categories[p.id], 'level', 0) === currentCategoryLevel && !isRealEstate
                                                ? PP_URLS.CATEGORY(p.slug[lang], lang)
                                                : (CATEGORIES_WITH_NEW_LANDING.indexOf(p.id) !== -1 || (p.level === 0 && !isCharity) || ((isRealEstate && !EXCLUDED_LANDING_FOR_CATEGORY.includes(p.id)) && !p.search))
                                                    ? PP_URLS.LANDING(lang, p.regionSlug ? `${p.regionSlug[lang]}` : p.slug[lang])
                                                    : PP_URLS.CATEGORY(p.regionSlug ? `${p.regionSlug[lang]}` : p.slug[lang], lang)
                                        })
                                    }
                                    data-search={p.search}
                                    key={p.id}
                                    aria-label={checkNested(p.name, lang) ? p.name[lang] : p.name}
                                >
                                    {checkNested(p.name, lang) ? p.name[lang] : p.name}
                                </Link>
                            )
                                : (
                                    <h1 className="fw-bold no-hover">
                                        {' '}
                                        {checkNested(p.name, lang) ? p.name[lang] : p.name}
                                    </h1>
                                )}
                        </li>
                    )
                ))}
                {amount > 0 ? <li className="breadcrumb-category__amount"><span className="badge badge-filters">{amount}</span></li> : ''}
            </ul>
        </div>
    );
};

const mapStateToProps = state => ({
    categories: getCategoriesById(state),
    lang: state.general.selectedLang
});

CategorySelect.defaultProps = {
    url: PP_URLS.CATEGORY
};

export default connect(mapStateToProps, { getCategoryData })(CategorySelect);
