import { GET_NEW_MESSAGES_COUNT } from 'ACTIONS';
import { API_TYPE } from 'CONST';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API, NOTIFICATION_OK, NOTIFICATION_POSITION } from 'CONFIG';
import { prepareSimplePayload } from 'MODULES/api';
import { apiPrefix } from 'ACTIONS/messaging/index';
import now from 'lodash/now';
import { notify } from 'ACTIONS/notifications/notify';

export const getNewMessages = (i18n) => (dispatch, getState) => {
    const state = getState();
    const currentMessageCount = state.messaging.unreadMessages || 0;
    const isFetching = state.ui.isFetching[GET_NEW_MESSAGES_COUNT];
    const offline = state.general.api_offline[API_TYPE.msg];
    if (offline.length > 0 && offline[offline.length - 1] >= now()) {
        return null;
    }
    if (!isFetching) {
        dispatch(
            apiAction(
                {
                    url: API_URLS.NEW_MESSAGES,
                    auth: true,
                    host: MESSAGING_API,
                    apiPrefix,
                    onSuccess: data => () => {
                        if (currentMessageCount < data.content.user) {
                            dispatch(notify({
                                message: i18n.__('New message received'),
                                status: NOTIFICATION_OK,
                                position: NOTIFICATION_POSITION
                            }));
                        }
                        const companyNewMessages = data.content.company || 0;
                        const userNewMessages = data.content.user || 0;
                        const notifications = data.content.notifications || 0;
                        dispatch({
                            type: GET_NEW_MESSAGES_COUNT,
                            payload: prepareSimplePayload(companyNewMessages + userNewMessages + notifications),
                            company: companyNewMessages,
                            user: userNewMessages
                        });
                    },
                    label: GET_NEW_MESSAGES_COUNT
                }
            )
        );
    }

    return null;
};
