import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API, MESSAGING_CONF } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';
import { UPDATE_LOT_THREADS } from 'ACTIONS';
import { apiPrefix } from 'ACTIONS/messaging/index';
import reverse from 'lodash/reverse';
import { getNewMessagesForThread } from 'ACTIONS/messaging/getNewMessagesForThread';
import { getUnixTimeStamp } from 'MODULES';
import last from 'lodash/last';

export const getAllThreadsForItem = (id, owner, order = 'DESC', fromTime = {}, timestamp, status = 'active', lot, fromHash = false, history, lotView) => (dispatch) => {
    dispatch(
        apiAction(
            {
                url: API_URLS.THREADS_BY_ID(id),
                auth: true,
                host: MESSAGING_API,
                data: { ...fromTime, order, pointer_direction: order },
                apiPrefix,
                onSuccess: data => () => {
                    const payload = checkNested(data, 'content');
                    if (payload) {
                        if (payload.data && payload.data.length > 0) {
                            if (fromHash) {
                                timestamp = getUnixTimeStamp(last(payload.data).updatedAt);
                                if (history) {
                                    history.replace({ hash: window.location.hash, search: window.location.search, state: { thread: timestamp, status, owner } });
                                }
                            }
                            reverse(payload.data).forEach((thread) => {
                                if (thread.totalMessages > 0 || lotView) {
                                    timestamp = lotView ? getUnixTimeStamp(thread.updatedAt) : timestamp;
                                    dispatch({
                                        type: UPDATE_LOT_THREADS,
                                        owner,
                                        payload: thread,
                                        isUpdate: fromTime && fromTime.from_time,
                                        timestamp,
                                        status,
                                        lot
                                    });
                                }
                                if (status === 'unread' || fromHash || lotView) {
                                    dispatch(getNewMessagesForThread(thread.uniqueHash, owner, timestamp, MESSAGING_CONF.messaging_sort, {}, thread.itemId, status));
                                }
                            });
                        }
                    }
                },
                label: UPDATE_LOT_THREADS
            }
        )
    );
};
