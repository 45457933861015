import React, { useState } from 'react';
import classNames from 'classnames';
import { AmountLoader } from 'COMMON/loader';
import { useDispatch, useSelector } from 'react-redux';
import { checkNested } from 'MODULES/checkNested';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { toggleBookmark } from 'ACTIONS/bookmarks/toggleBookmark';
import { getBoomarkedClasifiedsState } from 'MODULES/reselect/selectors/bookmarks';
import { useHistory } from 'react-router';
import Storage from 'HOC/storage';

const BookmarkButton = ({ pp, i18n, ppType, toggleRemoveBookmark, favoriteCount, lotsPath, activePage }) => {
    const [fetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const bookMarkedLots = useSelector(getBoomarkedClasifiedsState);
    const isBookmarked = checkNested(pp, 'isBookmarked');
    const isBookmarkedPP = checkNested(bookMarkedLots, [pp.id], isBookmarked);
    const isLoggedIn = Storage.get('login') === '1';

    const history = useHistory();
    const onBookmarkToggled = () => {
        setIsFetching(false);
    };
    if (fetching) {
        return <div className="fav-wrap">{AmountLoader}</div>;
    }
    return (
        <div
            className="fav-wrap"
            onClick={
                (e) => {
                    e.preventDefault();
                    setIsFetching(true);

                    if (isBookmarkedPP && ppType === PP_TYPE.bookmarks) {
                        toggleRemoveBookmark(onBookmarkToggled);
                    } else {
                        dispatch(toggleBookmark(pp, isBookmarkedPP, onBookmarkToggled, lotsPath, activePage, false, isLoggedIn, history));
                    }

                    // if (isLoggedIn) {
                    //
                    // } else {
                    //
                    //     setAlert();
                    //     // TODO: after signing ===> should be done - dispatch(toggleBookmark(pp, isBookmarkedPP, onBookmarkToggled, lotsPath, activePage, false, isLoggedIn));
                    // }
                }
            }
        >
            {favoriteCount > 0 && <div className="fav-count">{favoriteCount}</div>}
            <i
                title={(isBookmarkedPP) ? i18n.__('Remove from favorites') : i18n.__('Add to favorites')}
                className={classNames('pp-landing', {
                    'pp-heart bookmark-remove': isBookmarkedPP,
                    'pp-heart-o bookmark-add': !isBookmarkedPP
                })}
            />
        </div>
    );
};

export default BookmarkButton;
