import React from 'react';
import { Helmet } from 'react-helmet';
import { HOST, LANG_LIST } from 'CONFIG';
import isArray from 'lodash/isArray';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { checkNested } from 'MODULES/checkNested';

export const INITIAL_HELMET_STATE = { helmet: { jsx: [], params: {} } };

const renderTitleTag = (params, additions) => {
    const data = additions ? additions[0] : params[0];
    return <title key="title">{data.title}</title>;
};

const renderMetaTag = (params, additions) => {
    let metaTags = [];
    let mergedParams = null;
    params.forEach((param) => {
        mergedParams = { ...mergedParams, ...param };
    });
    additions.forEach((addition) => {
        mergedParams = { ...mergedParams, ...addition };
    });

    if (mergedParams) {
        Object.keys(mergedParams)
            .forEach((param) => {
                const type = param;
                const subTag = mergedParams[type][0];
                const content = mergedParams[type][1];
                const metaType = { [subTag]: type };
                if (type === 'og:image' && isArray(content)) {
                    content.forEach((value) => {
                        const item = value.file.file.outputType === 'video' ? value.file.thumbnail : value.file.file;
                        if (checkNested(item, ['converted']) || value.url) {
                            metaTags = [
                                ...metaTags,
                                <meta key={type} {...metaType} content={checkNested(item, ['converted']) ? buildImgUrl(item, 'hd') : value.url} />,
                                <meta key="width" property="og:image:width" content="1200" />,
                                <meta key="height" property="og:image:height" content="630" />
                            ];
                        }
                    });
                } else {
                    metaTags = [...metaTags, <meta key={type} {...metaType} content={content} />];
                }
            });
    }
    return metaTags;
};

const renderLinkTag = (params, additions) => {
    // gets all languages from global LANG_LIST variable
    const links = LANG_LIST;
    const data = additions || params;
    const callee = data[0];
    const slugs = data[1];
    const ppId = data[2];
    document.querySelectorAll('link[rel=\'canonical\']').forEach((l) => l.remove());
    return links.map((language) => {
        // this loop builds alternate link tags for all languages
        if (slugs[language]) {
            const isLanding = !slugs[language].includes('/');
            // checks whether such language exists in backend slugs response
            return (
                <link
                    key={language}
                    rel={language === 'lv' ? 'canonical' : 'alternate'}
                    hrefLang={language}
                    href={`${HOST}/${language}/${isLanding ? 'landing/' : ''}${slugs[language]}${callee === 'lot' ? `/!${ppId}` : ''}`}
                />
            );
        }
        return null;
    });
};

const renderTags = (state, additions) => Object.keys(state.params)
    .map((tag) => {
        switch (tag) {
            case 'link':
                return renderLinkTag(state.params[tag], checkNested(additions, [tag]));
            case 'meta':
                return renderMetaTag(state.params[tag], checkNested(additions, [tag]));
            case 'title':
                return renderTitleTag(state.params[tag], checkNested(additions, [tag]));
            default:
                break;
        }
    });


const buildHelmet = (state, params = null) => {
    // this builds tags that were set earlier and are already in this.state
    const tags = renderTags(state, params);
    if (tags.length) {
        return (
            <Helmet>
                {tags}
            </Helmet>
        );
    }
    return null;
};

export const prepareHelmet = (state, additions) => {
    const params = {
        ...state.helmet.params,
        ...additions
    };
    return {
        params,
        jsx: buildHelmet({ params }, additions)
    };
};

export default buildHelmet;
