import React from 'react';
import classnames from 'classnames';
import { checkNested } from 'MODULES/checkNested';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBookmark } from 'ACTIONS/bookmarks/toggleBookmark';
import { useRouteMatch, useHistory } from 'react-router';
import { categoriesGetPpByIdSelector } from 'MODULES/reselect/selectors/categories';
import { TOGGLE_BOOKMARK } from 'ACTIONS';
import { isUserLoggedIn } from 'MODULES/reselect/selectors/user';

const PpBookmarkButton = ({ i18n }) => {
    const isLoggedIn = useSelector(isUserLoggedIn);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.userData);
    const match = useRouteMatch();
    const ppId = match.params.ppid;
    const pp = useSelector((state) => categoriesGetPpByIdSelector(state, ppId));
    const isFetching = useSelector(state => state.ui.isFetching[TOGGLE_BOOKMARK][ppId]);
    const history = useHistory();
    const bookmarkClick = () => {
        dispatch(toggleBookmark(pp, pp.isBookmarked, false, false, false, userData, isLoggedIn, history));
    };
    return (
        checkNested(pp, 'status') === LOT_VISIBILITY.published.id && (
            <button
                className={classnames('btn-only-icon position-relative', { 'is-on': pp.isBookmarked })}
                id="bookmark-classified"
                title={i18n.__('Add to bookmarks')}
                data-test="bookmark-classified"
                onClick={(e) => {
                    e.preventDefault();
                    if (isFetching) {
                        return false;
                    }
                    bookmarkClick(pp, pp.isBookmarked, false, false, false, userData);
                }}
            >
                <i className={`pp-landing pp-heart${(pp.isBookmarked) ? '' : '-o'}`} />
                {pp.favoriteCount > 0 && (
                    <span className="badge badge-bookmarks bookmarks-count">{pp.favoriteCount}</span>
                )}
            </button>
        )
    );
};

export default PpBookmarkButton;
