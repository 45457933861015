export const formatCarEngineVolume = (inputValue) => {
    if (!inputValue) return '';
    // Remove any non-digit or dot characters
    let cleanValue = inputValue.replace(/[^0-9.]/g, '');

    // Ensure there's only one dot and position it after the first digit
    if (cleanValue.length > 1) {
        cleanValue = cleanValue.replace(/^(\d)(\d*)\.?(\d{0,3})?.*$/, '$1.$2$3');
    }

    return cleanValue;
};
