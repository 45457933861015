import React from 'react';
import keyBy from 'lodash/keyBy';
import { checkNested } from 'MODULES/checkNested';
import { filterId } from 'CONST';

const RealEstateFilterBlock = ({ filters, i18n }) => {
    const filtersById = keyBy(filters, 'filter.id');

    const floor = checkNested(filtersById, [filterId.floor, 'textValue']);
    const floorName = checkNested(filtersById, [filterId.floor, 'filter', 'name'], '');
    const floorIcon = checkNested(filtersById, [filterId.floor, 'filter', 'cssClass']);

    const floorsCount = checkNested(filtersById, [filterId.numberOfFloors, 'textValue']);
    const floorsIcon = checkNested(filtersById, [filterId.numberOfFloors, 'filter', 'cssClass']);
    const floorsName = checkNested(filtersById, [filterId.numberOfFloors, 'filter', 'name'], '');

    const houseType = checkNested(filtersById, [filterId.houseType, 'value', 'displayValue']);
    const houseTypeName = checkNested(filtersById, [filterId.houseType, 'filter', 'name'], '');
    const houseTypeIcon = 'pp-filter_house-type';

    const houseSeries = checkNested(filtersById, [filterId.houseSeries, 'value', 'displayValue']);
    const houseSeriesName = checkNested(filtersById, [filterId.houseSeries, 'filter', 'name'], '');

    const area = checkNested(filtersById, [filterId.areaM2, 'textValue']);
    const areaName = checkNested(filtersById, [filterId.areaM2, 'filter', 'name'], '');
    const areaIcon = checkNested(filtersById, [filterId.areaM2, 'filter', 'cssClass']);

    const room = checkNested(filtersById, [filterId.numberOfRooms, 'textValue']);
    const roomName = checkNested(filtersById, [filterId.numberOfRooms, 'filter', 'name'], '');
    const roomIcon = checkNested(filtersById, [filterId.numberOfRooms, 'filter', 'cssClass']);

    const land = checkNested(filtersById, [filterId.landArea, 'textValue']);
    const landName = checkNested(filtersById, [filterId.landArea, 'filter', 'name'], '');
    const landIcon = checkNested(filtersById, [filterId.landArea, 'filter', 'cssClass']);
    const estateFilters = [
        {
            condition: floor && floorsCount,
            title: floorName || floorsName,
            icon: floorIcon || floorsIcon,
            label: `${floor}/${floorsCount} ${i18n.p__('Short', 'Floor')}`
        },
        {
            condition: floor && !floorsCount,
            title: floorName,
            icon: floorIcon,
            label: `${floor} ${i18n.p__('Short', 'Floor')}`
        },
        {
            condition: !floor && floorsCount,
            title: floorsName,
            icon: floorsIcon,
            label: `${floorsCount} ${i18n.p__('Short', 'Floor')}`
        },
        {
            condition: area,
            title: areaName,
            icon: areaIcon,
            label: `${area}㎡`
        },
        {
            condition: room,
            title: roomName,
            icon: roomIcon,
            label: `${room} ${i18n.p__('Short', 'Room')}`
        },
        {
            condition: land,
            title: landName,
            icon: landIcon,
            label: `${land}㎡`
        }
    ];

    return (
        <div className="estate-filters">
            <div className="filter-list">
                {estateFilters.filter(filter => filter.condition).slice(0, 4).map((filter) => (
                    <div key={filter.label} className="text-nowrap" title={filter.title}>
                        <i className={`pp-landing ${filter.icon}`} />
                        {filter.label}
                    </div>
                ))}
            </div>
            {(houseType && !houseSeries) && (
                <div className="mt-1" title={houseTypeName}>
                    <i className={`pp-landing ${houseTypeIcon}`} />
                    {houseType}
                </div>
            )}
            {houseSeries && (
                <div className="mt-1" title={houseSeriesName}>
                    <i className={`pp-landing ${houseTypeIcon}`} />
                    {houseSeries}
                </div>
            )}
        </div>
    );
};

export default RealEstateFilterBlock;
