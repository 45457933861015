// Bookmarks
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const TOGGLE_BOOKMARK = 'TOGGLE_BOOKMARK';
export const GET_BOOKMARKED_TOTAL_COUNT = 'GET_BOOKMARKED_TOTAL_COUNT';
export const CLEAR_BOOKMARKS_STATE = 'CLEAR_BOOKMARKS_STATE';
export const INCREMENT_PP_FAVORITE = 'INCREMENT_PP_FAVORITE';
export const DECREMENT_PP_FAVORITE = 'DECREMENT_PP_FAVORITE';
export const INCREMENT_PP_FAVORITE_IN_LIST = 'INCREMENT_PP_FAVORITE_IN_LIST';
export const DECREMENT_PP_FAVORITE_IN_LIST = 'DECREMENT_PP_FAVORITE_IN_LIST';

// Compare
export const GET_COMPARE_COUNT = 'GET_COMPARE_COUNT';
export const ADD_LOT_COMPARE = 'ADD_LOT_COMPARE';
export const SET_COMPARE_LIST = 'SET_COMPARE_LIST';
export const DELETE_LOT_COMPARE = 'DELETE_LOT_COMPARE';
export const REMOVE_LOT_FROM_COMPARE_LIST = 'REMOVE_LOT_FROM_COMPARE_LIST';

// General
export const SAVE_LOCATION_HISTORY = 'SAVE_LOCATION_HISTORY';
export const SWITCH_LANG = 'SWITCH_LANG';

export const SWITCH_OFF_MESSAGING = 'SWITCH_OFF_MESSAGING';
export const FORM_CONFIG = 'FORM_CONFIG';
export const PREPARE_SIMPLE_FORMS = 'PREPARE_SIMPLE_FORMS';
export const PREPARE_DYNAMIC_FORMS = 'PREPARE_DYNAMIC_FORMS';
export const REPORTS_TYPE = 'REPORTS_TYPE';
export const REPORT_SENT = 'REPORT_SENT';
export const GET_LOT_REVIEWS = 'GET_LOT_REVIEWS';
export const GET_LOT_MY_REVIEWS = 'GET_LOT_MY_REVIEWS';
export const GET_LOT_THREAD_COUNT = 'GET_LOT_THREAD_COUNT';
export const INCREMENT_THREAD_COUNT = 'INCREMENT_THREAD_COUNT';
export const GET_FAVORITES_COUNT = 'GET_FAVORITES_COUNT';
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';
export const REVIEW_SENT = 'REVIEW_SENT';
export const REVIEW_PUBLISH = 'REVIEW_PUBLISH';
export const REVIEW_FILES = 'REVIEW_FILES';
export const PAGE_TIMESTAMP = 'PAGE_TIMESTAMP';
export const GET_STATIC_PAGE = 'GET_STATIC_PAGE';
export const GET_HOME_PARTNERS = 'GET_HOME_PARTNERS';
export const GET_PARTNERS_BY_CATEGORIES = 'GET_PARTNERS_BY_CATEGORIES';
export const SENDING_CONTACT = 'SENDING_CONTACT';
export const HISTORY_STATE = 'HISTORY_STATE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const API_OFFLINE = 'API_OFFLINE';
export const ADD_LOT_DISCOUNT = 'ADD_LOT_DISCOUNT';

export const GET_SINGLE_DELIVERY_POINT = 'GET_SINGLE_DELIVERY_POINT';
export const GET_DELIVERY_POINTS = 'GET_DELIVERY_POINTS';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';

export const GET_LOT_DELIVERIES = 'GET_LOT_DELIVERIES';
export const ADD_LOT_POSITION = 'ADD_LOT_POSITION';
export const UPDATE_LOT_PUBLISH = 'UPDATE_LOT_PUBLISH';
export const UPDATE_LOT_HIGHLIGHT = 'UPDATE_LOT_HIGHLIGHT';

// Search
export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SHOW_AUTOCOMPLETE_TOGGLER = 'SHOW_AUTOCOMPLETE_TOGGLER';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';

// feeds
export const GET_FEED_LIST = 'feeds/GET_FEED_LIST';
export const GET_FEED_BY_ID = 'feeds/GET_FEED_BY_ID';

export const DELETE_FEED_BY_ID = 'feeds/DELETE_FEED_BY_ID';
export const DISABLE_FEED_BY_ID = 'feeds/DISABLE_FEED_BY_ID';
export const ENABLE_FEED_BY_ID = 'feeds/ENABLE_FEED_BY_ID';
export const GET_FEED_LOG = 'feeds/GET_FEED_LOG';
export const GET_FEED_USER = 'feeds/GET_FEED_USER';
export const GET_FEED_MAPPED_CATEGORIES = 'GET_FEED_MAPPED_CATEGORIES';
export const GET_FEED_SUGGESTED_CATEGORIES = 'GET_FEED_SUGGESTED_CATEGORIES';
export const GET_FEED_SEARCH_CATEGORIES = 'GET_FEED_SEARCH_CATEGORIES';
export const CLEAR_FEED_SEARCH_SUGGEST = 'CLEAR_FEED_SEARCH_SUGGEST';
export const CLEAR_FEED_MAPPED_CATEGORY = 'CLEAR_FEED_MAPPED_CATEGORY';

// forms

export const GET_FORM_CONFIG = 'formdata/GET_FORM_CONFIG';
export const GET_FORM_CHANGE = '@@redux-form/CHANGE';

// Filters
export const SET_AREA = 'SET_AREA';
export const FILTER_OUT = 'FILTER_OUT';
export const SET_CHECKED_TREE = 'SET_CHECKED_TREE';
export const SET_EXPANDED_TREE = 'SET_EXPANDED_TREE';

export const SAVE_CATEGORY_FILTER = 'SAVE_CATEGORY_FILTER';
export const EXPAND_FILTER_FORM = 'EXPAND_FILTER_FORM';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const CATEGORY_REGIONS = 'CATEGORY_REGIONS';
export const GET_POPULAR_REGIONS_TREE = 'GET_POPULAR_REGIONS_TREE';

export const GET_POPULAR_REGIONS_LANDING = 'GET_POPULAR_REGIONS_LANDING';
export const GET_STREETS = 'GET_STREETS';
export const CATEGORY_SUB_REGIONS = 'CATEGORY_SUB_REGIONS';
export const CATEGORY_SUB_REGIONS_LEVEL_2 = 'CATEGORY_SUB_REGIONS_LEVEL_2';
export const FLUSH_ALL_FILTERS = 'FLUSH_ALL_FILTERS';
export const SET_FILTERS_HISTORY = 'SET_FILTERS_HISTORY';
export const SORT_LOTS = 'SORT_LOTS';
export const GET_CATEGORY_FILTERS_BY_ID = 'GET_CATEGORY_FILTERS_BY_ID';
export const FLUSH_SELECTED_FILTER = 'FLUSH_SELECTED_FILTER';
export const GET_FILTER_LOT_COUNT = 'GET_FILTER_LOT_COUNT';
export const GET_TREE_CATEGORIES = 'GET_TREE_CATEGORIES';
export const SELECT_FILTER_CATEGORY = 'SELECT_FILTER_CATEGORY';
export const SELECT_FILTER_CATEGORY_RESTORE = 'SELECT_FILTER_CATEGORY_RESTORE';
export const REMOVE_FILTER_CATEGORY_VALUE = 'REMOVE_FILTER_CATEGORY_VALUE';
export const REMOVE_FILTER_CATEGORY = 'REMOVE_FILTER_CATEGORY';
export const RESET_INITIAL_FILTERS = 'RESET_INITIAL_FILTERS';

// UI
export const CHAIN_CATEGORY_SELECT_LEVELS = 'CHAIN_CATEGORY_SELECT_LEVELS';
export const CHAIN_CATEGORY_SELECT_CHAIN_DATA = 'CHAIN_CATEGORY_SELECT_CHAIN_DATA';
export const CHAIN_CATEGORY_SELECT_SELECTED = 'CHAIN_CATEGORY_SELECT_SELECTED';
export const IS_FETCHING = 'IS_FETCHING';
export const START_UPLOAD = 'START_UPLOAD';
export const REMOVE_UPLOADING_FILE = 'REMOVE_UPLOADING_FILE';
export const IS_TOGGLED = 'IS_TOGGLED';
export const NOTIFY_MESSAGE = 'NOTIFY_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const MODAL_WINDOW = 'MODAL_WINDOW';
export const MODAL_LOADER = 'MODAL_LOADER';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_CONTACT = 'SHOW_CONTACT';
export const NOT_FOUND = 'NOT_FOUND';
export const CLEAR_NOT_FOUND = 'CLEAR_NOT_FOUND';
export const SET_TREE_DATA = 'SET_TREE_DATA';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';
export const SWITCH_CLASSIFIEDS_VIEW = 'SWITCH_CLASSIFIEDS_VIEW';
export const SERVER_ERROR = 'SERVER_ERROR';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const CLEAR_UI_STATE = 'CLEAR_UI_STATE'; // Is not used anywhere for now.
export const CLEAR_UI_NOTIFICATIONS = 'CLEAR_UI_NOTIFICATIONS';
export const SET_UI_HISTORY = 'SET_UI_HISTORY';
export const RESET_FILTER_FORM = 'RESET_FILTER_FORM';
export const ACTIVATE_SEARCH_INPUT = 'ACTIVATE_SEARCH_INPUT';
export const FILTER_CREATE_FORM = 'FILTER_CREATE_FORM';
export const SET_UI_MESSAGE = 'SET_UI_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';
export const REMOVE_NOTIFICATION_MESSAGE = 'REMOVE_NOTIFICATION_MESSAGE';
export const SET_CONFIRMATION_DATA = 'SET_CONFIRMATION_DATA';
export const MODAL_CLOSE_ACTION = 'MODAL_CLOSE_ACTION';
export const MULTIPLE_SELECT = 'MULTIPLE_SELECT';
export const MULTIPLE_SELECT_EMPTY = 'MULTIPLE_SELECT_EMPTY';
export const SET_WATCH_HISTORY = 'SET_WATCH_HISTORY';
export const INITIAL_UI_STATE = 'INITIAL_UI_STATE';
export const ACTIVATE_COORDINATES = 'ACTIVATE_COORDINATES';
export const SET_CLASSIFIED_VISITED = 'SET_CLASSIFIED_VISITED';

// Payments
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const CLEAR_PAYMENTS_STATE = 'CLEAR_PAYMENTS_STATE';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const ACTIVATE_EXPIRED_LOTS = 'ACTIVATE_EXPIRED_LOTS';
export const SUBSCRIPTION_TEMPLATES = 'SUBSCRIPTION_TEMPLATES';
export const SET_USER_CONTACTS_FEE = 'SET_USER_CONTACTS_FEE';
export const SET_TOLL_CATEGORY_FEE = 'SET_TOLL_CATEGORY_FEE';
export const SET_SUGGEST_FEE = 'SET_SUGGEST_FEE';
export const SET_BOOST_TO_TOP_FEE = 'SET_BOOST_TO_TOP_FEE';
export const SET_XML_BOOST_TO_TOP_FEE = 'SET_XML_BOOST_TO_TOP_FEE';
export const SET_TIK_TOK_FEE = 'SET_TIK_TOK_FEE';
export const SET_INSTAGRAM_FEE = 'SET_INSTAGRAM_FEE';
export const SET_HIGHLIGHT_FEE = 'SET_HIGHLIGHT_FEE';
export const SET_LOT_BOOST_BANNER_FEE = 'SET_LOT_BOOST_BANNER_FEE';

// Create PP New
export const CREATE_CLASSIFIED = 'CREATE_CLASSIFIED';
export const CLEAR_CREATE_DATA = 'CLEAR_CREATE_DATA';
export const CREATE_STEP = 'CREATE_STEP';
export const SET_TIPS_FOR_PHOTO = 'SET_TIPS_FOR_PHOTO';
export const SWITCH_CREATE_VIEW = 'SWITCH_CREATE_VIEW';
export const CREATE_SET_COMPONENT_DATA = 'CREATE_SET_COMPONENT_DATA';

export const GET_LOCATION_ADDRESS = 'GET_LOCATION_ADDRESS';

export const GET_RECENT_SEARCHES = 'GET_RECENT_SEARCHES';
export const SEARCH_GEO_DATA = 'SEARCH_GEO_DATA';
export const SET_ROTATION_DATA = 'SET_ROTATION_DATA';
export const SET_PP_DATA = 'SET_PP_DATA';
export const CLONE_CLASSIFIED = 'CLONE_CLASSIFIED';
// home
export const CHANGE_HOME_ENABLED_CATEGORIES = 'CHANGE_HOME_ENABLED_CATEGORIES';
export const GET_HOME_DISABLED_CATEGORIES = 'GET_HOME_DISABLED_CATEGORIES';
export const CHANGE_HOME_DISABLED_CATEGORIES = 'CHANGE_HOME_DISABLED_CATEGORIES';
export const GET_HOME_SUBCATEGORIES = 'GET_HOME_SUBCATEGORIES';
export const GET_HOME_FILTERS = 'GET_HOME_FILTERS';
export const GET_HOME_CATEGORIES = 'GET_HOME_CATEGORIES';
export const GET_HOME_CATEGORIES_LOTS = 'GET_HOME_CATEGORIES_LOTS';
export const GET_HOME_CATEGORIES_ACTIVE_CHILD = 'GET_HOME_CATEGORIES_ACTIVE_CHILD';
export const GET_HOME_CATEGORIES_AD_COUNT = 'GET_HOME_CATEGORIES_AD_COUNT';
export const WARM_UP_MORE_TO_LOVE = 'WARM_UP_MORE_TO_LOVE';
export const SET_FILTER_CATEGORY = 'SET_FILTER_CATEGORY';
export const GET_HOME_COUNT = 'GET_HOME_COUNT';
export const GET_QR_CODE = 'GET_QR_CODE';
// Create PP
export const PUBLISH_WO_CONTACTS = 'PUBLISH_WO_CONTACTS';
export const GET_VEHICLE_DATA = 'GET_VEHICLE_DATA';
export const CREATE_PP_SUBCATEGORIES = 'CREATE_PP_SUBCATEGORIES';
export const GET_RECENTLY_INSERTED_CATEGORY = 'GET_RECENTLY_INSERTED_CATEGORY';
export const CREATE_PP_SUBCATEGORIES_TREE = 'CREATE_PP_SUBCATEGORIES_TREE';
export const REGISTER_CATEGORY_ID = 'REGISTER_CATEGORY_ID';
export const PP_SAVED = 'PP_SAVED';
export const GET_IMAGE_VERSIONS = 'GET_IMAGE_VERSIONS';
export const GET_LOT_FILES = 'GET_LOT_FILES';
export const UPLOAD_MULTIPLE_FILES = 'UPLOAD_MULTIPLE_FILES';
export const GET_LOT_FILES_CLASSIFIED = 'GET_LOT_FILES_CLASSIFIED';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPDATE_FILE_PREVIEW = 'UPDATE_FILE_PREVIEW';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const NEW_FILE = 'NEW_FILE';
export const ADD_FILES = 'ADD_FILES';
export const ROTATE_IMG = 'ROTATE_IMG';
export const STORE_ROTATE_IMG_DATA = 'STORE_ROTATE_IMG_DATA';
export const CLEAR_ROTATE_IMG_DATA = 'CLEAR_ROTATE_IMG_DATA';
export const REMOVE_ROTATE_IMG_DATA = 'REMOVE_ROTATE_IMG_DATA';
export const MEDIA_IS_CONVERTING = 'MEDIA_IS_CONVERTING';
export const SET_FILES = 'SET_FILES';
export const REMOVE_FILE = 'REMOVE_FILE';
export const FLUSH_FILES = 'FLUSH_FILES';
export const CLEAR_CREATEPP_STATE = 'CLEAR_CREATEPP_STATE';
export const SEARCH_GEO = 'SEARCH_GEO';
export const SET_PP_LOCATION = 'SET_PP_LOCATION';
export const CHANGE_LOT_STATUS = 'CHANGE_LOT_STATUS';
export const RENEW_CLASSIFIED = 'RENEW_CLASSIFIED';
export const CATEGORY_HINTS = 'CATEGORY_HINTS';

export const CHANGE_LOT_STATUS_ALL = 'CHANGE_LOT_STATUS_ALL';
export const SET_PP_LIST = 'SET_PP_LIST';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const UPDATE_CLASSIFIED_STATUS = 'UPDATE_CLASSIFIED_STATUS';
export const UPDATE_PP_LIST_COUNT = 'UPDATE_PP_LIST_COUNT';
export const UPDATE_LIST_LOT = 'UPDATE_LIST_LOT';
export const FILE_PROGRESS = 'FILE_PROGRESS';
export const CLEAR_RESPONSE_DATA = 'CLEAR_RESPONSE_DATA';

export const CREATE_THUMB = 'CREATE_THUMB';

// Company
export const COMPANY_PAGE_SELECTED_CATEGORY = 'COMPANY_PAGE_SELECTED_CATEGORY';
export const COMPANY_PAGE_CURRENT_CATEGORY = 'COMPANY_PAGE_CURRENT_CATEGORY';
export const COMPANY_PAGE_SHOW_FILTERS = 'COMPANY_PAGE_SHOW_FILTERS';
export const SEND_DATA_TO_AUTEGO = 'SEND_DATA_TO_AUTEGO';
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA';
export const GET_COMPANY_MEMBERS = 'GET_COMPANY_MEMBERS';
export const GET_COMPANY_CATEGORIES = 'GET_COMPANY_CATEGORIES';
export const GET_COMPANY_FEEDS = 'GET_COMPANY_FEEDS';
export const DELETE_XML_FEED = 'DELETE_XML_FEED';
export const CLEAR_COMPANY_STATE = 'CLEAR_COMPANY_STATE';
export const CLEAR_COMPANY_FEEDS = 'CLEAR_COMPANY_FEEDS';
export const SET_COMPANY_HISTORY = 'SET_COMPANY_HISTORY';
export const GET_COMPANY_COORDINATES = 'GET_COMPANY_COORDINATES';

// Messaging
export const MESSAGE_THREAD = 'MESSAGE_THREAD';
export const GET_MESSAGES = 'GET_MESSAGES';
export const FETCHING_CLASSIFIED_MESSAGES = 'FETCHING_CLASSIFIED_MESSAGES';
export const GET_THREADS = 'GET_THREADS';
export const ACTIVATE_THREAD = 'ACTIVATE_THREAD';
export const FLUSH_ALL_MESSAGES = 'FLUSH_ALL_MESSAGES';
export const FLUSH_ALL_THREADS = 'FLUSH_ALL_THREADS';
export const FLUSH_ALL_LOTS = 'FLUSH_ALL_LOTS';
export const GET_MESSAGE_LOTS = 'GET_MESSAGE_LOTS';
export const POST_CONVERSATION_MESSAGE = 'POST_CONVERSATION_MESSAGE';
export const UPDATE_THREAD = 'UPDATE_THREAD';
export const CLEAR_MESSAGING_STATE = 'CLEAR_MESSAGING_STATE';
export const PATCHING_MESSAGE = 'PATCHING_MESSAGE';

export const ADD_DELIVERY_POINT = 'ADD_DELIVERY_POINT';
export const GET_NEW_MESSAGES_COUNT = 'GET_NEW_MESSAGES_COUNT';
export const GET_THREADS_BY_ID = 'GET_THREADS_BY_ID';
export const UPDATE_LOT_THREADS = 'UPDATE_LOT_THREADS';
export const SET_THREAD_FROM_HASH = 'SET_THREAD_FROM_HASH';
export const GET_MESSAGES_FOR_LOT = 'GET_MESSAGES_FOR_LOT';
export const SEND_OFFER = 'SEND_OFFER';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const PATCH_NOTIFICATION = 'PATCH_NOTIFICATION';

// Categories

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA';
export const FLUSH_CATEGORY_DATA = 'FLUSH_CATEGORY_DATA';
export const BUILD_CATEGORY_PARENTS = 'BUILD_CATEGORY_PARENTS';
export const GET_PPS_LIST = 'GET_PPS_LIST';
export const GET_PPS_CATEGORY = 'GET_PPS_CATEGORY';
export const GET_PPS_ARRAY = 'GET_PPS_ARRAY';
export const GET_PPS_BY_PAGE = 'GET_PPS_BY_PAGE';
export const GET_SMALL_CLASSIFIED_LIST = 'GET_SMALL_CLASSIFIED_LIST';
export const GET_PP = 'GET_PP';
export const SET_PP_HELMET_DATA = 'SET_PP_HELMET_DATA';
export const GET_SIMILAR_PP = 'GET_SIMILAR_PP';
export const GET_LOTS_BY_BUILDING = 'GET_LOTS_BY_BUILDING';
export const GET_LOT_AMENITIES = 'GET_LOT_AMENITIES';
export const CLEAR_LOT_FILES = 'CLEAR_LOT_FILES';
export const CHANGE_PP_DATA = 'CHANGE_PP_DATA';
export const DELETE_PP = 'DELETE_PP';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const ADD_ACTIVE_PAGE = 'ADD_ACTIVE_PAGE';
export const SEND_STAT_DATA = 'SEND_STAT_DATA';
export const SET_CATEGORIES_HISTORY = 'SET_CATEGORIES_HISTORY';
export const INITIAL_PP_STATE = 'INITIAL_PP_STATE';
export const INITIAL_PP_STATE_BY_TYPE = 'INITIAL_PP_STATE_BY_TYPE';
export const CHECK_SUBSCRIBERS = 'CHECK_SUBSCRIBERS';
export const ADD_SUBSCRIBER = 'ADD_SUBSCRIBERS';
export const GET_INFINITE_LIST = 'GET_INFINITE_LIST';
export const FETCH_INFINITE_LIST = 'FETCH_INFINITE_LIST';
export const GET_CATEGORIES_FULL_TREE = 'GET_CATEGORIES_FULL_TREE';
export const GET_CATEGORIES_WITH_PARENTS = 'GET_CATEGORIES_WITH_PARENTS';
export const ADD_MISSING_CATEGORIES = 'ADD_MISSING_CATEGORIES';

// FilterSubscriptions
export const DELETE_USER_FILTER = 'DELETE_USER_FILTER';
export const SAVE_USER_FILTER = 'SAVE_USER_FILTER';
export const MARK_FILTER_LOTS_AS_SEEN = 'MARK_FILTER_LOTS_AS_SEEN';
export const UPDATE_USER_FILTER = 'UPDATE_USER_FILTER';
export const PATCH_FILTER_HASH = 'PATCH_FILTER_HASH';
export const SET_UNSEEN_FILTERS = 'SET_UNSEEN_FILTERS';
export const SAVED_USER_FILTERS = 'SAVED_USER_FILTERS';
export const PATCH_FILTERED_CLASSIFIED = 'PATCH_FILTERED_CLASSIFIED';
export const CLEAR_FILTER_SUBSCRIPTION_STATE = 'CLEAR_FILTER_SUBSCRIPTION_STATE';
export const SET_FILTER_SUBSCRIPTIONS_HISTORY = 'SET_FILTER_SUBSCRIPTIONS_HISTORY';
export const REMOVE_FILTER_COUNT = 'REMOVE_FILTER_COUNT';
export const REMOVE_LOT_UNSEEN_LIST = 'REMOVE_LOT_UNSEEN_LIST';
export const GET_UNSEEN_FILTER_LOTS = 'GET_UNSEEN_FILTER_LOTS';
export const MARK_LOT_AS_SEEN = 'MARK_LOT_AS_SEEN';
export const CLEAR_UNSEEN_FILTER_LOTS = 'CLEAR_UNSEEN_FILTER_LOTS';

// User
export const SIGNING_IN = 'SIGNING_IN';
export const LOGIN = 'LOGIN';
export const GET_WATCH_ID = 'GET_WATCH_ID';
export const LOGOUT = 'LOGOUT';
export const SIGN_UP = 'SIGN_UP';
export const CHECK_USER_COOKIE = 'CHECK_USER_COOKIE';
export const GET_USER = 'GET_USER';
export const UPLOAD_FEED = 'UPLOAD_FEED';
export const GET_FEED = 'GET_FEED';
export const GET_FEED_LOGS = 'GET_FEED_LOGS';
export const GET_FEED_CATEGORIES = 'GET_FEED_CATEGORIES';
export const GET_ALL_FEEDS = 'GET_ALL_FEEDS';
export const GET_USER_AVATAR = 'GET_USER_AVATAR';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_REFERRAL = 'GET_USER_REFERRAL';
export const GET_USER_CONTACTS = 'GET_USER_CONTACTS';
export const SAVED_USER_CONTACT = 'SAVED_USER_CONTACT';
export const PREFERED_CONTACT_UPDATE = 'PREFERED_CONTACT_UPDATE';
export const RESEND_USER_CONTACT = 'RESEND_USER_CONTACT';
export const VERIFY_USER_CONTACT = 'VERIFY_USER_CONTACT';
export const CHECK_VERIFIED_MAIL = 'CHECK_VERIFIED_MAIL';
export const DELETE_USER_CONTACT = 'DELETE_USER_CONTACT';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const GET_SOCIAL_CONTACTS = 'GET_SOCIAL_CONTACTS';
export const SAVE_USER_SOCIAL_CONTACT = 'SAVE_USER_SOCIAL_CONTACT';
export const DELETE_USER_SOCIAL_ACCOUNT = 'DELETE_USER_SOCIAL_ACCOUNT';
export const PASSWORD_RECOVERY_DATA = 'PASSWORD_RECOVERY_DATA';
export const PASSWORD_RECOVERY_HASH = 'PASSWORD_RECOVERY_HASH';
export const PASSWORD_RECOVERY_NEW = 'PASSWORD_RECOVERY_NEW';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_PASSWORD_PERMISSIONS = 'UPDATE_PASSWORD_PERMISSIONS';
export const REQUEST_NEW_USER_PASSWORD = 'REQUEST_NEW_USER_PASSWORD';
export const DELETE_AVATAR = 'DELETE_AVATAR';
export const SEND_LANG_TO_API = 'SEND_LANG_TO_API';
export const GET_USER_OFFERS = 'GET_USER_OFFERS';
export const USER_INVOICES = 'USER_INVOICES';
export const USER_PDF_INVOICE = 'USER_PDF_INVOICE';
export const GET_USER_CATEGORIES = 'GET_USER_CATEGORIES';
export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const PATCH_USER_PREFERENCE = 'PATCH_USER_PREFERENCE';
export const FLUSH_USER_CATEGORIES = 'FLUSH_USER_CATEGORIES';
export const USER_ACTIVE_PACKAGES = 'USER_ACTIVE_PACKAGES';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';
export const GET_MY_LOTS_COUNT = 'GET_MY_LOTS_COUNT';
export const GET_MY_COMPANY_LOTS_AMOUNT = 'GET_MY_COMPANY_LOTS_AMOUNT';
export const GET_PARTICIPANT_INFO = 'GET_PARTICIPANT_INFO';
export const ACCESS_TOKEN_HANDLER = 'ACCESS_TOKEN_HANDLER';
export const ACCEPT_COMPANY_INVITE = 'ACCEPT_COMPANY_INVITE';
export const CHANGE_COMPANY_USER_DATA = 'CHANGE_COMPANY_USER_DATA';
export const ADD_USER_LOT = 'ADD_USER_LOT';
export const REMOVE_USER_LOT = 'REMOVE_USER_LOT';
export const INCREMENT_USER_LOT = 'INCREMENT_USER_LOT';
export const ADD_COMPANY_LOT = 'ADD_COMPANY_LOT';
export const REMOVE_COMPANY_LOT = 'REMOVE_COMPANY_LOT';
export const INCREMENT_COMPANY_LOT = 'INCREMENT_COMPANY_LOT';
export const GET_MESSAGING_LOT_LIST = 'GET_MESSAGING_LOT_LIST';
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const GET_LOT_STATISTIC = 'GET_LOT_STATISTIC';
export const AUTH_TOKENS = 'AUTH_TOKENS';
export const SET_HANDLE_STEP = 'SET_HANDLE_STEP';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const GET_MEMBER_CONTACTS = 'GET_MEMBER_CONTACTS';
export const MY_WALLET_DATA = 'MY_WALLET_DATA';
export const MY_WALLET_LOG_DATA = 'MY_WALLET_LOG_DATA';
// User (Company)
export const GET_USER_COMPANY = 'GET_USER_COMPANY';
export const GET_COMPANY_SOCIAL_CONTACTS = 'GET_COMPANY_SOCIAL_CONTACTS';
export const SAVE_COMPANY_SOCIAL_CONTACT = 'SAVE_COMPANY_SOCIAL_CONTACT';
export const DELETE_COMPANY_SOCIAL_ACCOUNT = 'DELETE_COMPANY_SOCIAL_ACCOUNT';
export const GET_USER_COMPANY_PERMISSIONS = 'GET_USER_COMPANY_PERMISSIONS';
export const GET_USER_COMPANY_MEMBERS = 'GET_USER_COMPANY_MEMBERS';
export const CLEAR_LOT_MEMBER_CONTACTS = 'CLEAR_LOT_MEMBER_CONTACTS';
export const GET_COMPANY_MEMBER = 'GET_COMPANY_MEMBER';
export const GET_USER_COMPANY_MEMBERS_PERMISSIONS = 'GET_USER_COMPANY_MEMBERS_PERMISSIONS';
export const DELETE_USER_COMPANY_MEMBER = 'DELETE_USER_COMPANY_MEMBER';
export const USER_COMPANY_INVITES = 'USER_COMPANY_INVITES';
export const USER_COMPANY_INVITES_PERMISSIONS = 'USER_COMPANY_INVITES_PERMISSIONS';
export const GET_USER_COMPANY_INVITES_PERMISSIONS = 'GET_USER_COMPANY_INVITES_PERMISSIONS';
export const CREATE_COMPANY_PERMISSIONS = 'CREATE_COMPANY_PERMISSIONS';
export const RESEND_USER_COMPANY_INVITE = 'RESEND_USER_COMPANY_INVITE';
export const DELETE_USER_COMPANY_INVITE = 'DELETE_USER_COMPANY_INVITE';
export const GET_COMPANIES_AVATAR = 'GET_COMPANIES_AVATAR';
export const GET_INVITATION_DATA = 'GET_INVITATION_DATA';
export const CLEAR_INVITATION_DATA = 'CLEAR_INVITATION_DATA';
export const RESET_USER_STATE = 'RESET_USER_STATE';

// Hints
export const GET_HINTS = 'GET_HINTS';
export const DELETE_HINTS = 'DELETE_HINTS';

// XML DOCS
export const GET_XML_DOCS = 'GET_XML_DOCS';

// API
export const API = 'API';
export const API_END = 'API_END';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';
export const API_START = 'API_START';
export const API_ACCESS = 'API_ACCESS';

export const API_PAYMENT_NEED = 'API_PAYMENT_NEED';

// ROUTE CACHE
export const SAVE_ROUTE_TO_CACHE = 'SAVE_ROUTE_TO_CACHE';

// SSR
export const SET_TOKENS = 'SET_TOKENS';
export const SHOULD_UPDATE_COOKIES = 'SHOULD_UPDATE_COOKIES';

// pps list
export const GET_LOT_COUNT_MULTIPLE = 'GET_LOT_COUNT_MULTIPLE';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';
export const SET_QUERY_STRING_WITHOUT_COORDINATES = 'SET_QUERY_STRING_WITHOUT_COORDINATES';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';

export const UPDATE_FILTER_VALUES = 'UPDATE_FILTER_VALUES';

export const SET_LOT_LIST = 'SET_LOT_LIST';
export const REMOVE_COMPARE_LOT = 'REMOVE_COMPARE_LOT';
export const SET_LOT_LIST_COUNT = 'SET_LOT_LIST_COUNT';
export const GET_PPS_LIST_BY_ID = 'GET_PPS_LIST_BY_ID';
