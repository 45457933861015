import React from 'react';
import { INITIAL_HELMET_STATE, prepareHelmet } from 'MODULES/helmet';
import { HTML_HEAD_DATA } from 'CONFIG';

import { checkNested } from 'MODULES/checkNested';
import { defaultMeta } from 'CONFIG/defaultMeta';
import { customTitle } from 'CONFIG/meta_tags';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';

const withHelmet = (DecoratedComponent) => {
    class hoc extends React.Component {
        state = INITIAL_HELMET_STATE;

        componentWillMount() {
            this.drawHelmet(this.props);
        }

        componentDidUpdate(prevProps) {
            const nextProps = this.props;
            if (nextProps.helmet && ((!checkNested(prevProps, 'helmet.title') && nextProps.helmet.title) || (prevProps.helmet.title !== nextProps.helmet.title))) {
                this.drawHelmet(nextProps);
            }
        }

        drawHelmet = (props) => {
            const categoryId = checkNested(this.props, 'categoriesData.id', '') || checkNested(this.props, 'categoriesData.currentCategory.id', '');
            const { lang, location } = this.props;
            const customTitleString = customTitle(categoryId, lang);
            const currentCategory = checkNested(this.props, `categoriesData.name[${lang}]`) || checkNested(this.props, `categoriesData.currentCategory.name[${lang}]`);
            const lotTitle = this.props.pp ? checkNested(this.props, ['helmet', 'title']) : checkNested(props, ['helmet', 'slogan'], `${checkNested(props.helmet, ['title'], HTML_HEAD_DATA.slogan)}`);
            const additions = {
                title: [{
                    title: customTitleString || (currentCategory ? `${currentCategory} - Bezmaksas sludinājumi` : lotTitle)
                }],
                meta: []
            };
            Object.keys(defaultMeta).forEach((key) => {
                const contentMeta = checkNested(props, ['helmet', key]) || defaultMeta[key].defaultValue;
                additions.meta.push({ [key]: [defaultMeta[key].subTag, contentMeta] });
            });

            if (checkNested(props, ['helmet', 'keywords'])) {
                additions.meta.push({ keywords: ['name', props.helmet.keywords.join(', ')] });
            }
            if (checkNested(props, ['helmet', 'link', 'slug'])) {
                const ppID = checkNested(props, ['helmet', 'link', 'ppid']);
                additions.link = [checkNested(props, ['helmet', 'link', 'callee']), checkNested(props, ['helmet', 'link', 'slug']), ppID];
            }
            // noindex nofollow tag instructs robots to not index and to not crawl pages
            // modal map with empty filter result, deleted, blocked, emptyList - considered as soft 404 pages
            if (location.pathname.includes('modal-map')
                || (checkNested(props, ['pp', 'status'], 0) === LOT_VISIBILITY.blocked.id)
                || (checkNested(props, ['pp', 'status'], 0) === LOT_VISIBILITY.deleted.id)
                || (checkNested(props, 'ppCount') === 0)
            ) {
                additions.meta.push({ robots: ['name', 'noindex, nofollow'] });
            }
            this.setState({
                helmet: { ...prepareHelmet(this.state, additions) }
            });
        };

        render() {
            return (
                <div>
                    {this.state.helmet.jsx}
                    <DecoratedComponent {...this.props} />
                </div>
            );
        }
    }
    return hoc;
};

export default withHelmet;
