import { language } from 'MODULES/language';

export const timeDifference = (previous, i18n, current = new Date()) => {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    const lang = language();

    if (typeof Intl.RelativeTimeFormat !== 'function') {
        // returns publish date for legacy browser
        return formatDate(previous);
    }
    const rtf = new Intl.RelativeTimeFormat(lang, {
        localeMatcher: 'best fit',
        numeric: 'always',
        style: 'long'
    });

    if (elapsed < msPerMinute) {
        return i18n.__('A few second ago');
    }

    if (elapsed < msPerHour) {
        const min = Math.round(elapsed / msPerMinute);
        return rtf.format(-min, 'minute');
    }

    if (elapsed < msPerDay) {
        const hours = Math.round(elapsed / msPerHour);
        return rtf.format(-hours, 'hour');
    }

    if (elapsed < msPerMonth) {
        const days = Math.round(elapsed / msPerDay);
        return rtf.format(-days, 'day');
    }

    if (elapsed < msPerYear) {
        const month = Math.round(elapsed / msPerMonth);
        return rtf.format(-month, 'month');
    }

    const years = Math.round(elapsed / msPerYear);

    return rtf.format(`-${years}`, 'years');
};


export const formatDate = (dateToFormat, returnFunction, isCrc32, isOmniva) => {
    const date = new Date(dateToFormat);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    let hh = date.getHours();
    let min = date.getMinutes();
    let ss = date.getSeconds();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    if (hh < 10) hh = `0${hh}`;
    if (min < 10) min = `0${min}`;
    if (ss < 10) ss = `0${ss}`;

    if (isOmniva) {
        const formatterMonth = new Intl.DateTimeFormat('lv', { month: 'long' });
        const d = new Date(dateToFormat);
        const month1 = formatterMonth.format(d);

        return `${dd}. ${month1}, ${hh}:${min}`;
    }

    if (isCrc32) {
        return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    }

    if (returnFunction) {
        returnFunction(dd, mm, yyyy);
        return null;
    }

    return `${dd}-${mm}-${yyyy}`;
};


export const addDaysToDate = (dateToAdd, plusDays) => {
    const date = new Date(dateToAdd);
    date.setDate(date.getDate() + plusDays);
    return date;
};

export const getDifferenceInSeconds = (dateFrom, dateTo) => {
    const startDate = dateFrom;
    const endDate = dateTo;
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
};

export const getDifferenceInDays = (dateFrom, dateTo) => {
    const date1 = dateFrom;
    const date2 = dateTo;

    const differenceInTime = date2.getTime() - date1.getTime();
    return Math.round(differenceInTime / (1000 * 3600 * 24));
};
