/* eslint-disable camelcase */
import { useEffect } from 'react';
import { useLocation } from 'react-router';


const GemiusListener = () => {
    const location = useLocation();
    useEffect(() => {
        if (typeof pp_gemius_hit !== 'undefined') {
            pp_gemius_hit('zZ2aoScuowDST4XCDmL9tXaInIPBfqPTIXGMnM5UCOr.K7');
        }
    }, [location]);
    return null;
};

export default GemiusListener;
