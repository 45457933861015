import qs from 'qs';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
    CATEGORY_REGIONS,
    CATEGORY_SUB_REGIONS,
    CATEGORY_SUB_REGIONS_LEVEL_2,
    EXPAND_FILTER_FORM,
    FILTER_OUT,
    FLUSH_ALL_FILTERS,
    FLUSH_SELECTED_FILTER,
    GET_CATEGORY_FILTERS,
    GET_CATEGORY_FILTERS_BY_ID,
    GET_FILTER_LOT_COUNT, GET_LOT_COUNT_MULTIPLE,
    GET_STREETS,
    GET_TREE_CATEGORIES,
    REMOVE_FILTER_CATEGORY,
    REMOVE_FILTER_CATEGORY_VALUE,
    RESET_INITIAL_FILTERS,
    SAVE_CATEGORY_FILTER,
    SELECT_FILTER_CATEGORY,
    SELECT_FILTER_CATEGORY_RESTORE,
    SET_CHECKED_TREE,
    SET_EXPANDED_TREE,
    SORT_LOTS
} from 'ACTIONS';
import { checkNested } from 'MODULES/checkNested';
import { setIn } from 'MODULES/setIn';
import forEach from 'lodash/forEach';

export const INITIAL_FILTER_STATE = {
    categoryFilters: null,
    filterValues: '',
    filterToString: 'default',
    patchFilterHash: null,
    checkedTree: [],
    expandedTree: [],
    streets: {},
    regions: null,
    sort: null,
    timeStamp: null,
    filterLotsCount: null,
    expandedForm: { 1: true },
    lastExpandedFilter: 1,
    filtersByCategoryId: {},
    categoryTree: {},
    filerByCategory: { },
    lotCountByParams: {}
};

export default function (state = INITIAL_FILTER_STATE, action) {
    const pathname = action.req || decodeURIComponent(window.location.pathname).replace('.', '_');
    const stateUse = checkNested(state, pathname) ? state : {
        ...state,
        [pathname]: INITIAL_FILTER_STATE
    };
    switch (action.type) {
        case GET_CATEGORY_FILTERS:
            return { ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    categoryFilters: { ...action.payload.data, filtersById: action.payload.filtersById, filterToUseInList: action.payload.filterToUseInList, filterSortOptions: action.payload.filterSortOptions, actionsById: keyBy(checkNested(action.payload.data, 'actions'), 'id') }
                }
            };
        case GET_LOT_COUNT_MULTIPLE:
            const payloads = action.payload;
            let dispatchData = {};

            forEach(payloads, (p) => {
                if (p.querystring) {
                    dispatchData = { ...dispatchData, [p.querystring]: checkNested(p.response, 'count', '0') };
                }
            });
            return { ...stateUse,
                [pathname]: {
                    ...stateUse[pathname], lotCountByParams: { ...stateUse.lotCountByParams, ...dispatchData } } };
        case GET_STREETS:
            return { ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    streets: checkNested(action.payload, 'content', undefined)
                }
            };
        case CATEGORY_SUB_REGIONS_LEVEL_2:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    regions: {
                        ...stateUse[pathname].regions,
                        ...stateUse[pathname].child,
                        childByLetters: action.payload.groupByLetters,
                        secondChild: { ...keyBy(action.data, 'id') }
                    }
                }
            };
        case GET_CATEGORY_FILTERS_BY_ID:
            return {
                ...state,
                filtersByCategoryId:
                    {
                        ...state.filtersByCategoryId,
                        [action.category]: action.payload
                    }
            };
        case FLUSH_ALL_FILTERS:
            return setIn(pathname, INITIAL_FILTER_STATE, state);
        case FILTER_OUT:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filterValues: action.payload,
                    filterToString: qs.stringify(omit(action.payload, 'page')) || 'default'
                }
            };
        case SET_CHECKED_TREE:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    checkedTree: action.payload
                }
            };
        case SET_EXPANDED_TREE:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    expandedTree: action.payload
                }
            };
        case SAVE_CATEGORY_FILTER:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    categoryFilter: action.payload
                }
            };
        case CATEGORY_REGIONS:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    regions: {
                        ...stateUse[pathname].regions,
                        parent: action.payload.data,
                        parentsByLetters: action.payload.groupByLetters,
                        regionsBySlug: action.payloadBySlug
                    }
                }
            };
        case CATEGORY_SUB_REGIONS:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    regions: {
                        ...stateUse[pathname].regions,
                        child: map(action.payload.data, c => ({ ...c, parent: checkNested(action.payload.parent, 'id') || action.payload.parent })),
                        childByLetters: action.payload.groupByLetters,
                        regionsBySlug: action.payloadBySlug
                    }
                }
            };
        case SORT_LOTS:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    sort: action.payload
                }
            };
        case FLUSH_SELECTED_FILTER:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filterToString: INITIAL_FILTER_STATE.filterToString,
                    filterValues: INITIAL_FILTER_STATE.filterValues,
                    filerByCategory: { }
                }
            };
        case GET_FILTER_LOT_COUNT:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filterLotsCount: action.payload
                }
            };
        case EXPAND_FILTER_FORM:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    expandedForm: !action.isMobile ? { ...stateUse[pathname].expandedForm, [action.payload]: action.stateType } : { [action.payload]: action.stateType },
                    lastExpandedFilter: action.stateType ? action.payload : stateUse[pathname].lastExpandedFilter
                }
            };
        case GET_TREE_CATEGORIES:
            if (action.dispatchOnParent) {
                return {
                    ...stateUse,
                    [pathname]: {
                        ...stateUse[pathname],
                        categoryTree: {
                            ...checkNested(stateUse[pathname], 'categoryTree', {}),
                            [`childrens_${action.dispatchOnParent}`]: action.payload
                        }
                    }
                };
            }
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    categoryTree: { ...checkNested(stateUse[pathname], 'categoryTree', {}), ...action.payload }
                }
            };
        case SELECT_FILTER_CATEGORY:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filerByCategory: {
                        ...stateUse[pathname].filerByCategory,
                        [action.index]: action.category !== ''
                            ? {
                                ...checkNested(stateUse[pathname].filerByCategory, action.index, {}),
                                [action.level]: action.category
                            }
                            : omit(stateUse[pathname].filerByCategory[action.index], action.level)
                    }
                }
            };
        case SELECT_FILTER_CATEGORY_RESTORE:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filerByCategory: action.payload ? action.payload : {}
                }
            };
        case REMOVE_FILTER_CATEGORY:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filerByCategory: omit(stateUse[pathname].filerByCategory, action.index)
                }
            };
        case REMOVE_FILTER_CATEGORY_VALUE:
            return {
                ...stateUse,
                [pathname]: {
                    ...stateUse[pathname],
                    filerByCategory: {
                        ...stateUse[pathname].filerByCategory,
                        [action.field]: omit(stateUse[pathname].filerByCategory[action.field], action.index)
                    }
                }
            };
        case RESET_INITIAL_FILTERS:
            return {
                ...stateUse,
                [pathname]: INITIAL_FILTER_STATE
            };
        default:
            return state;
    }
}
