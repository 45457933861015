// LOT_VISIBILITY
export const LOT_VISIBILITY = {
    draft: {
        id: 1,
        name: 'draft',
        text: i18n => (i18n.__('Draft')),
        showFilter: true,
        defaultTrue: true,
        showRightColumn: true
    },
    published: {
        id: 2,
        name: 'published',
        text: i18n => (i18n.__('Published')),
        showFilter: true,
        defaultTrue: true,
        showRightColumn: true
    },
    pending_publish: {
        id: 7,
        name: 'pending_publish',
        text: i18n => (i18n.__('Waiting approval')),
        showFilter: true,
        defaultTrue: true,
        showRightColumn: true
    },
    closed: {
        id: 4,
        name: 'closed',
        text: i18n => (i18n.__('Closed')),
        showFilter: true,
        defaultTrue: false,
        showRightColumn: true
    },
    blocked: { id: 6, name: 'blocked', text: i18n => (i18n.__('Blocked')), showFilter: true, defaultTrue: true },
    deleted: { id: 5, name: 'deleted', text: i18n => (i18n.__('Deleted')), showFilter: false },
    expired: {
        id: 9,
        name: 'expired',
        text: i18n => (i18n.__('Expired')),
        showFilter: true,
        defaultTrue: false,
        showRightColumn: true
    },
    xml_draft: { id: 8, name: 'xml_draft', text: i18n => (i18n.__('Draft')), showFilter: false }
};
