export const customTitle = (categoryId, lang) => {
    let customTitleString;
    if (categoryId === 1) {
        customTitleString = lang === 'ru' ? 'Транспорт и техника - объявления на PP.lv' : 'Transporta un tehnikas sludinājumi - PP.lv';
    }
    if (categoryId === 3809) {
        customTitleString = lang === 'ru' ? 'Недвижимость в Латвии - смотреть объявления на PP.lv' : 'Nekustamais īpašums Latvijā, sludinājumi - PP.lv';
    }
    if (categoryId === 6105) {
        customTitleString = lang === 'ru' ? 'Одежда и обувь - смотреть объявления на PP.lv' : 'Sludinājumi: apģērbs, apavi, aksesuāri - PP.lv';
    }
    if (categoryId === 2799) {
        customTitleString = lang === 'ru' ? 'Услуги и работа - смотреть объявления на PP.lv' : 'Darba sludinājumi, vakances un pakalpojumi - PP.lv';
    }
    if (categoryId === 3807) {
        customTitleString = lang === 'ru' ? 'Здоровье и красота, объявления по всей Латвии - PP.lv' : 'Veselība un Skaistums, sludinājumi visā Latvijā - PP.lv';
    }
    if (categoryId === 3692) {
        customTitleString = lang === 'ru' ? 'Объявления о животных, всё для домашних питомцев - PP.lv' : 'Dzīvnieku sludinājumi, viss mājdzīvniekiem - PP.lv';
    }
    if (categoryId === 3731) {
        customTitleString = lang === 'ru' ? 'Всё для сада - покупка, продажа. Объявления на PP.lv' : 'Viss Dārzam - Sludinājumi. Pērk, pārdod - PP.lv';
    }
    if (categoryId === 3680) {
        customTitleString = lang === 'ru' ? 'Электротехника, бытовая техника. Объявления - PP.lv' : 'Elektrotehnika, sadzīves tehnika. Sludinājumi - PP.lv';
    }
    if (categoryId === 4423) {
        customTitleString = lang === 'ru' ? 'Объявления о строительных материалах и услугах - PP.lv' : 'Sludinājumi celtniecības precēm un pakalpojumiem - PP.lv ';
    }
    if (categoryId === 5409) {
        customTitleString = lang === 'ru' ? 'Всё для дома - ищи, покупай, продавай. Объявления - PP.lv' : 'Viss mājai - meklē, pērc, pārdod. Sludinājumi - PP.lv';
    }
    if (categoryId === 3470) {
        customTitleString = lang === 'ru' ? 'Продукты питания и напитки - объявления PP.lv' : 'Pārtika un dzērieni - sludinājumi PP.lv';
    }
    if (categoryId === 6621) {
        customTitleString = lang === 'ru' ? 'Сельское хозяйство - смотреть объявления на PP.lv' : 'Lauksaimniecība - visi sludinājumi PP.lv';
    }
    if (categoryId === 6439) {
        customTitleString = lang === 'ru' ? 'Производство - смотреть объявления на PP.lv' : 'Ražošana - visi sludinājumi PP.lv';
    }
    if (categoryId === 6737) {
        customTitleString = lang === 'ru' ? 'Товары для детей, малышей - все объявления PP.lv' : 'Preces bērniem, mazuļiem - visi sludinājumi PP.lv';
    }
    if (categoryId === 7041) {
        customTitleString = lang === 'ru' ? 'Товары для отдыха и хобби - объявления на PP.lv' : 'Sludinājumi atpūtas un hobiju precēm - PP.lv';
    }
    if (categoryId === 7829) {
        customTitleString = lang === 'ru' ? 'Отдай, поменяй или подари - все объявления PP.lv' : 'Atdod, maina vai dāvina - visi sludinājumi PP.lv';
    }
    return customTitleString;
};

export const customDescription = (categoryId, lang) => {
    let customDescriptionString;
    if (categoryId === 1) {
        customDescriptionString = lang === 'ru' ? 'Транспорт во всех регионах: автомобили, мотоциклы, спецтехника, запчасти. Новый и б/у транспорт' : 'Bezmaksas sludinājumi: Transports un Tehnika, Vieglie auto, Smagais transports, Moto transports, Riepas, Diski, Rezerves daļas, Velo, Aksesuāri u.c.';
    }
    if (categoryId === 3809) {
        customDescriptionString = lang === 'ru' ? 'Объявления - купля, продажа, аренда. Квартиры, дома, земля, дачи, офисы, коммерция, модули и др.' : 'Bezmaksas sludinājumi: Nekustamais ipasums - dzīvokļi, mājas, zeme, dārzi, biroji, komerciālie īpašumi, moduļi un citi.';
    }
    if (categoryId === 6105) {
        customDescriptionString = lang === 'ru' ? 'Бесплатные объявления по всей Латвии: одежда, обувь и аксессуары для всей семьи. Выгодно купи/продай.' : 'Bezmaksas sludinājumi visā Latvijā: apģērbi, apavi un aksesuāri visai ģimenei. Izdevīgi pērk/pārdod.';
    }
    if (categoryId === 2799) {
        customDescriptionString = lang === 'ru' ? 'Объявления о работе и вакансиях в Латвии и Риге. Найдите специалистов и компании для бытовых и проф. услуг.' : 'Darba un vakances sludinājumi Latvijā un Rīgā. Atrodiet speciālistus un uzņēmumus sadzīves un profesionālajiem pakalpojumiem.';
    }
    if (categoryId === 3807) {
        customDescriptionString = lang === 'ru' ? 'Товары для красоты и здоровья, услуги. Смотрите или добавляйте новые объявления бесплатно на PP.lv.' : 'Skaistumkopšanas un veselības preces, pakalpojumi. Skatieties vai pievienojiet jaunus sludinājumus bez maksas PP.lv.';
    }
    if (categoryId === 3692) {
        customDescriptionString = lang === 'ru' ? 'Собаки, кошки, птицы и другие животные, игрушки, клетки, уход, домики, террариумы, еда, ветеринария и др.' : 'Suņi, kaķi, putni un citi dzīvnieki, rotaļlietas, būri, kopšana, mājiņas, terāriji, barība, veterinārija u.c.';
    }
    if (categoryId === 3731) {
        customDescriptionString = lang === 'ru' ? 'Товары для сада: мебель, рабочая одежда, техника, триммеры, орошение, уборка и многое другое.' : 'Preces dārzam: mēbeles, darba apģērbs, tehnika, trimmeri, laistīšana, tīrīšana un daudz kas cits.';
    }
    if (categoryId === 3680) {
        customDescriptionString = lang === 'ru' ? 'Электроника, бытовая техника: телевизоры, холодильники, стиральные машины и др. Бесплатные объявления - PP.lv' : 'Elektronika, sadzīves tehnika: televizori, ledusskapji, veļas mašīnas u.c. Bezmaksas sludinājumi - PP.lv';
    }
    if (categoryId === 4423) {
        customDescriptionString = lang === 'ru' ? 'Стройматериалы, инструменты, услуги. Найдите или предложите строительные решения. Бесплатные объявления - PP.lv.' : 'Celtniecības materiāli, instrumenti, pakalpojumi. Atrodiet vai piedāvājiet būvniecības risinājumus. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 5409) {
        customDescriptionString = lang === 'ru' ? 'Товары для дома: мебель, интерьер, бытовая техника, комнатные растения, химия, дрова и др.' : 'Preces mājai: mēbeles, interjers, sadzīves tehnika, istabas augi, ķīmija, malka u.c.';
    }
    if (categoryId === 3470) {
        customDescriptionString = lang === 'ru' ? 'Продукты, напитки, деликатесы. Найдите или предложите свежие продукты и напитки. Бесплатные объявления - PP.lv.' : 'Pārtika, dzērieni, delikateses. Atrodiet vai piedāvājiet svaigus produktus un dzērienus. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 6621) {
        customDescriptionString = lang === 'ru' ? 'Сельхозтехника, семена, животные. Купи/продай всё для сельского хозяйства. Бесплатные объявления - PP.lv.' : 'Lauksaimniecības tehnika, sēklas, dzīvnieki. Pērc/pārdod visu lauksaimniecībai. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 6439) {
        customDescriptionString = lang === 'ru' ? 'Оборудование, материалы, промышленность. Найдите или предложите решения для производства. Бесплатные объявления - PP.lv.' : 'Iekārtas, materiāli, rūpniecība. Atrodiet vai piedāvājiet ražošanas risinājumus. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 6737) {
        customDescriptionString = lang === 'ru' ? 'Игрушки, одежда, мебель для детей и малышей. Купи или продай товары для детей. Бесплатные объявления - PP.lv.' : 'Rotaļlietas, apģērbs, mēbeles bērniem un mazuļiem. Pērc vai pārdod preces bērniem. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 7041) {
        customDescriptionString = lang === 'ru' ? 'Спорттовары, товары для хобби, путешествия. Найдите или предложите товары для отдыха. Бесплатные объявления - PP.lv.' : 'Sporta preces, preces hobijiem, ceļojumiem. Atrodiet vai piedāvājiet preces atpūtai. Bezmaksas sludinājumi - PP.lv.';
    }
    if (categoryId === 7829) {
        customDescriptionString = lang === 'ru' ? 'Отдайте, обменяйте или подарите вещи. Бесплатные объявления для поиска нового дома для ненужных вещей - PP.lv.' : 'Atdodiet, mainiet vai dāviniet lietas. Bezmaksas sludinājumi jauna mājokļa atrašanai nevajadzīgām lietām - PP.lv.';
    }
    return customDescriptionString;
};



