import {
    ADD_FILES,
    CHANGE_LOT_STATUS,
    CHANGE_STATUS,
    CLEAR_CREATE_DATA, CLEAR_RESPONSE_DATA, CLEAR_ROTATE_IMG_DATA,
    CREATE_SET_COMPONENT_DATA,
    CREATE_STEP,
    FILE_PROGRESS,
    FLUSH_FILES,
    GET_IMAGE_VERSIONS, GET_LOCATION_ADDRESS,
    GET_LOT_FILES, GET_VEHICLE_DATA,
    NEW_FILE,
    PP_SAVED,
    REMOVE_FILE, REMOVE_ROTATE_IMG_DATA,
    REMOVE_UPLOADING_FILE,
    RESET_USER_STATE,
    SEARCH_GEO,
    SET_FILES,
    SET_HANDLE_STEP,
    SET_PP_DATA, SET_ROTATION_DATA, SET_TIPS_FOR_PHOTO,
    START_UPLOAD, STORE_ROTATE_IMG_DATA,
    SWITCH_CREATE_VIEW, UPDATE_FILE_PREVIEW,
    UPLOAD_FILE, UPLOAD_MULTIPLE_FILES
} from 'ACTIONS';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import { CREATE_VIEWS } from 'MODULES/const';
import { FILES_API } from 'CONFIG';
import { stringExistsIn } from 'MODULES/validations';
import { checkNested } from 'MODULES/checkNested';
import { setIn } from 'MODULES/setIn';


const INITIAL_STATE = {
    activeStep: 0,
    showPrev: false,
    uploadingFiles: [],
    componentData: {},
    files: {},
    createView: CREATE_VIEWS.steps,
    rotatedFiles: {},
    response: {},
    rotationData: {},
    responseFromMap: [],
    tipsForPhoto: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TIPS_FOR_PHOTO:
            return { ...state, tipsForPhoto: action.payload };
        case CREATE_STEP:
            return {
                ...state,
                ...action.payload
            };
        case SWITCH_CREATE_VIEW:
            return {
                ...state,
                createView: action.payload
            };
        case CREATE_SET_COMPONENT_DATA:
            let componentData = setIn(action.path, action.payload, state.componentData);
            if (action.filter) {
                componentData = setIn(action.filter.path, pickBy(checkNested(componentData, action.filter.path, {}), (i, k) => (k <= action.filter.filter)), componentData);
            }
            return {
                ...state,
                componentData
            };
        case FILE_PROGRESS:
            return {
                ...state,
                fileProgress: setIn(action.payload.fileID, action.payload.percentCompleted, state.fileProgress)
            };
        case SET_ROTATION_DATA:
            return {
                ...state,
                rotationData: action.payload
            };
        case GET_LOT_FILES:
            return { ...state, response: { ...state.response, files: action.payload } };
        case GET_VEHICLE_DATA:
            return { ...state, vehicleData: { ...state.vehicleData, [action.vehicleNumber]: action.payload } };
        case PP_SAVED:
        case CHANGE_STATUS:
            return { ...state, response: { ...action.payload, address: action.address } };
        case UPLOAD_MULTIPLE_FILES:
            return { ...state,
                files: { ...state.files,
                    [action.lot]: {
                        ...checkNested(state.files, action.lot, {}),
                        ...action.payload
                    }
                } };
        case UPLOAD_FILE:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.lot]: {
                        ...checkNested(state.files, action.lot, {}),
                        [action.fileID]: {
                            ...state.files[action.fileID],
                            backendRotate: 0,
                            backendJob: false,
                            uploadError: false,
                            fileToRetry: null,
                            backendId: action.payload.id,
                            deleteID: action.payload.id,
                            thumbnail: action.payload.thumbnail
                        }
                    }
                }
            };
        case UPDATE_FILE_PREVIEW:
            if (!stringExistsIn('.webp', checkNested(state.files, [action.fileID, 'preview']))) {
                return {
                    ...state,
                    files: {
                        ...state.files,
                        [action.lot]: {
                            ...checkNested(state.files, action.lot, {}),
                            [action.fileID]: {
                                ...checkNested(state.files, [action.lot, action.fileID], {}),
                                preview: `${FILES_API}${action.preview}`
                            }
                        }

                    }
                };
            }
            return { ...state };
        case GET_IMAGE_VERSIONS:
            const files = keyBy(state.response.files, 'file.id');
            return {
                ...state,
                response: {
                    ...state.response,
                    files: sortBy({ ...files,
                        [action.payload.backendId]: { ...files[action.payload.backendId],
                            file: { ...files[action.payload.backendId].file, versions: action.payload.versions }
                        } }, 'sequence')
                }
            };
        case START_UPLOAD:
            return { ...state, uploadingFiles: !action.method ? state.uploadingFiles.filter(d => (d !== action.id)) : [...state.uploadingFiles, action.id] };
        case REMOVE_UPLOADING_FILE:
            return { ...state, uploadingFiles:  state.uploadingFiles.filter(d => (d !== action.payload)) };
        case SET_HANDLE_STEP:
            return { ...state, formValidation: action.payload };
        case SET_FILES:
            return { ...state,
                files: { ...state.files,
                    [action.lot]: {
                        ...checkNested(state.files, action.lot, {}),
                        ...action.payload
                    }
                } };
        case NEW_FILE:
            return { ...state,
                files: { ...state.files,
                    [action.lot]: {
                        ...checkNested(state.files, action.lot, {}),
                        [action.payload.fileID]: action.payload }
                }

            };
        case ADD_FILES:
            return { ...state, files: { ...state.files, [action.lot]: { ...checkNested(state.files, action.lot, {}), ...action.payload } } };

        case FLUSH_FILES:
            return { ...state, files: {} };
        case REMOVE_FILE:
            return { ...state,
                files: {
                    ...state.files, [action.lot]:  omit(state.files[action.lot], action.payload)
                } };
        case STORE_ROTATE_IMG_DATA:
            return { ...state,
                rotatedFiles: {
                    ...state.rotatedFiles,
                    [action.payload.id]: action.payload
                }
            };
        case CLEAR_ROTATE_IMG_DATA:
            return { ...state, rotatedFiles: {} };
        case REMOVE_ROTATE_IMG_DATA:
            return { ...state, rotatedFiles: omit(state.rotatedFiles, action.payload.id) };
        case SEARCH_GEO:
            return { ...state, searchGeo: action.payload };
        case SET_PP_DATA:
            return {
                ...state, response: action.payload
            };
        case CHANGE_LOT_STATUS:
            return { ...state, lastStatusChange: { error: action.error, pp: action.payload, status: action.status } };
        case CLEAR_RESPONSE_DATA: {
            return { ...state, response: {} };
        }
        case GET_LOCATION_ADDRESS: {
            return { ...state, responseFromMap: action.payload };
        }

        case CLEAR_CREATE_DATA:
            return INITIAL_STATE;
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
