import {
    CHANGE_LOT_STATUS,
    CHANGE_LOT_STATUS_ALL,
    CHANGE_STATUS,
    CLEAR_RESPONSE_DATA,
    GET_PP,
    UPDATE_CLASSIFIED_STATUS,
    UPDATE_LIST_LOT
} from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import qs from 'qs';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { checkNested } from 'MODULES/checkNested';
import { prepareSimplePayload } from 'MODULES/api';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { RESPONSE_CODES_API } from 'CONST';
import { MODALS } from 'MODULES/MODALS';
import { notification } from 'ACTIONS/notifications/notification';
import { error } from 'ACTIONS/notifications/error';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { showErrorMsg } from 'ACTIONS/ui/showErrorMsg';
import { removeErrorMsg } from 'ACTIONS/ui/removeErrorMsg';
import { showModal } from 'ACTIONS/ui/showModal';
import { getUserPackages } from 'ACTIONS/user/getUserPackages';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import { getMyWallet } from 'ACTIONS/user/getMyWallet';
import { showModalWithData } from 'ACTIONS/ui/showModalWithData';

export const changeLotStatus = (id, type, action, onSuccess = null, resolve = null, reject = null, handler = CHANGE_LOT_STATUS, lotsPath) => (dispatch) => {
    dispatch(setIsFetching(CHANGE_LOT_STATUS, true));
    dispatch(removeErrorMsg(handler));
    dispatch(
        apiAction({
            url: `${API_URLS.LOTS}${qs.stringify({ lots: isArray(id) ? id : [id] }, {
                addQueryPrefix: true,
                arrayFormat: 'brackets'
            })}`,
            label: CHANGE_LOT_STATUS_ALL,
            method: 'PATCH',
            auth: true,
            successOnError: true,
            data: { adStateUserAction: type },
            onSuccess: payload => () => {
                if (payload.content) {
                    payload.content.forEach((lot, index) => {
                        dispatch({
                            type: UPDATE_LIST_LOT,
                            payload: lot
                        });
                        if (window.location.pathname.indexOf(PP_URLS.ITEMS) === -1) {
                            dispatch({
                                type: CHANGE_LOT_STATUS,
                                payload: checkNested(payload.content, 'length') > 1 ? lot : prepareSimplePayload(payload.content[0]),
                                status: checkNested(payload.content, 'status') === LOT_VISIBILITY.pending_publish.id ? LOT_VISIBILITY.pending_publish.name : LOT_VISIBILITY.published.name
                            });
                            dispatch({
                                type: CHANGE_LOT_STATUS,
                                payload: checkNested(payload.content, 'length') > 1 ? lot : prepareSimplePayload(payload.content[0]),
                                status: checkNested(payload.content, 'status') === LOT_VISIBILITY.pending_publish.id ? LOT_VISIBILITY.pending_publish.name : LOT_VISIBILITY.published.name
                            });
                        }
                        if (checkNested(action, 'lotsPath')) dispatch({ type: CLEAR_RESPONSE_DATA }); // clear create data response when lot status change on my items
                        if (!checkNested(action, 'lotsPath') && window.location.pathname.indexOf(PP_URLS.ITEMS) === -1) {
                            dispatch({
                                type: CHANGE_STATUS,
                                payload: checkNested(payload.content, 'length') > 1 ? lot : prepareSimplePayload(payload.content[0])
                            });
                        }
                        if (lotsPath) {
                            dispatch({
                                type: UPDATE_CLASSIFIED_STATUS,
                                payload: checkNested(payload.content, 'length') > 1 ? lot.status : prepareSimplePayload(payload.content[0].status),
                                path: `${lotsPath[0]}.${lotsPath[1]}.${lotsPath[2]}.${PP_TYPE.list}.id-${lot.id}.status`
                            });
                        }
                        dispatch({
                            type: GET_PP,
                            payload: checkNested(payload.content, 'length') > 1 ? lot.id : prepareSimplePayload(payload.content[0]),
                            id: checkNested(payload.content, 'length') > 1 ? id[index] : id
                        });
                        dispatch(setIsFetching([GET_PP, checkNested(payload.content, 'length') > 1 ? id[index] : id], false));
                        if (action) {
                            dispatch({
                                ...action,
                                payload: {
                                    ...prepareSimplePayload(checkNested(payload.content, 'length') > 1 ? lot : prepareSimplePayload(payload.content[0])),
                                    status: checkNested(payload.content, '0.status') === LOT_VISIBILITY.pending_publish.id ? LOT_VISIBILITY.pending_publish.name : LOT_VISIBILITY.published.name
                                }
                            });
                        }
                    });

                    if (onSuccess) {
                        onSuccess();
                        dispatch(notification(payload));
                    }
                    dispatch(getUserPackages());
                    dispatch(setIsFetching(CHANGE_LOT_STATUS, false));
                    if (resolve) {
                        resolve(payload.content);
                    }
                }

                if (payload.error) {
                    let errorFunc = () => {
                        dispatch(setIsFetching(CHANGE_LOT_STATUS, false));
                        dispatch(error(payload));
                        dispatch({
                            type: CHANGE_LOT_STATUS,
                            error: payload.error,
                            status: type
                        });
                    };
                    const errorMessages = checkNested(payload, 'error');
                    const isUserForbidden = findIndex(errorMessages, ['code', RESPONSE_CODES_API.USER_FORBIDDEN]) !== -1;
                    const isTollCategory = findIndex(errorMessages, ['code', RESPONSE_CODES_API.NOT_ENOUGH_CREDITS_TOLL_CATEGORY]) !== -1;
                    if (isTollCategory) dispatch(getMyWallet());
                    if (reject && !isUserForbidden) {
                        errorFunc = () => {
                            dispatch(setIsFetching(CHANGE_LOT_STATUS, false));
                            dispatch(showErrorMsg(handler, errorMessages));
                            reject(payload);
                        };
                    } else if (isUserForbidden) {
                        errorFunc = () => {
                            dispatch(showModal(false));
                            dispatch(showModalWithData({ classified: id }, MODALS.contacts, true));
                            dispatch(setIsFetching(CHANGE_LOT_STATUS, false));
                            dispatch({
                                type: CHANGE_LOT_STATUS,
                                error: payload.error,
                                status: type
                            });
                        };
                    }
                    errorFunc();
                }
            }
        })
    );
};
