import { API, API_ACCESS, API_END, API_PAYMENT_NEED, API_START, API_SUCCESS } from 'ACTIONS/index';
import { API as API_HOST } from 'CONFIG/API';
import { notification } from 'ACTIONS/notifications/notification';
import { error } from 'ACTIONS/notifications/error';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { showNotifyMsg } from 'ACTIONS/ui/showNotifyMsg';


export const apiEnd = (label, chained) => (dispatch) => {
    if (!chained) {
        dispatch({
            type: API_END,
            payload: label
        });
        dispatch(setIsFetching(label, false));
    }
};

export const apiError = label => ({
    type: API_END,
    payload: label
});


export const apiSuccess = label => ({
    type: API_SUCCESS,
    payload: label
});

export const apiAccess = label => ({
    type: API_ACCESS,
    payload: label
});

export const apiPayments = label => ({
    type: API_PAYMENT_NEED,
    payload: label
});


export const apiStart = label => (dispatch) => {
    dispatch({
        type: API_START,
        payload: label
    });
    dispatch(setIsFetching(label, true));
};


export const apiAction = props => dispatch => new Promise((resolve, reject) => {
    const {
        url = '',
        body,
        responseType,
        notificationFunction = (label, msg) => () => dispatch(showNotifyMsg(label, msg.notification)),
        msg = false,
        method = 'GET',
        onUploadProgress,
        successOnError = false,
        notificationLabel = null,
        data = null,
        headers = { },
        onSuccess = () => {
        },
        onFailure = () => {
        },
        apiPrefix,
        formError = true,
        label = 'ACTION',
        returnParams = {},
        withCredentials = true,
        host = API_HOST,
        controller,
        auth
    } = props;

    dispatch({
        type: API,
        payload: {
            responseType,
            controller,
            url,
            method,
            onUploadProgress,
            formError,
            notificationLabel,
            data,
            host,
            body,
            withCredentials,
            onSuccess,
            notificationFunction,
            onFailure,
            label,
            resolve,
            msg,
            reject,
            headers,
            returnParams,
            apiPrefix,
            successOnError,
            auth
        }
    });
}).then().catch(() => { });

export const promiseBaseAction = (data, dispatch, returnHeaders, action, actionParams, showNotifications) => new Promise((resolve, reject) => {
    dispatch(apiAction(
        { ...data,
            onSuccess: (payload, headers) => () => {
                if (showNotifications) {
                    dispatch(notification(payload));
                }
                if (returnHeaders) {
                    return resolve(headers);
                }
                if (action) {
                    dispatch({
                        type: action,
                        payload,
                        ...actionParams
                    });
                }

                return resolve(payload, headers);
            },
            onFailure: payload => () => {
                if (showNotifications) {
                    dispatch(error(payload));
                }
                reject(payload);
            } }
    ));
});
