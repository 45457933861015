import React from 'react';
import size from 'lodash/size';
import TyreIcon from 'COMPONENTS/masonry/lot_filters_icons/tyre-icon';
import FormattedPrice from 'COMPONENTS/classified/price_formatter';
import RealEstateFilterBlock from 'COMPONENTS/masonry/lot_filters_icons/realEstateFilterBlock';
import classNames from 'classnames';
import RequiredFilter from 'COMPONENTS/masonry/lot_filters_icons/requiredFilter';
import CategoryFilters from 'COMPONENTS/masonry/lot_filters_icons/categoryFilters';
import { checkNested } from 'MODULES/checkNested';
import { CATEGORIES } from 'MODULES/CATEGORIES';

const FilterIcons = ({ allFilters, category, filters, lotView, lot, requiredFilters, i18n }) => {
    const parentCategoryId = checkNested(category, 'parent.id');
    const listClassName = classNames({
        'estate-list': parentCategoryId === CATEGORIES.realEstate,
        'filter-list': parentCategoryId !== CATEGORIES.realEstate
    });
    return (
        <div className={listClassName}>
            {lotView && (
                <FormattedPrice
                    prices={lot.prices}
                    action={lot.action}
                    useWrapper
                    hideIcons
                    i18n={i18n}
                    hideVat
                    hideType
                />
            )}
            {parentCategoryId !== CATEGORIES.tyres
            && parentCategoryId !== CATEGORIES.realEstate
            && !lotView
            && <CategoryFilters filters={filters} category={parentCategoryId} i18n={i18n} />}
            {lotView && size(requiredFilters) > 0
            && <RequiredFilter requiredFilters={requiredFilters} />}
            {parentCategoryId === CATEGORIES.tyres
            && <TyreIcon category={category.name} filters={allFilters} />}
            {parentCategoryId === CATEGORIES.realEstate
            && <RealEstateFilterBlock category={category.name} filters={allFilters} i18n={i18n} />}
        </div>
    );
};

export default FilterIcons;
