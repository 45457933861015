import React from 'react';
import { checkNested } from 'MODULES/checkNested';
import getMotDateValue from 'MODULES/getMotDateValue';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { FILTER_DISPLAY_TYPE, filterId } from 'CONST';

const CategoryFilters = ({ filters, category, i18n }) => filters.map(f => {
    const filterData = f.filter?.filter || {};
    const cssClass = checkNested(filterData, 'cssClass', '');
    const filter = filterData.id;

    const isMotorcycle = category === CATEGORIES.motorcycle;
    const model = filter === filterId.model;
    const transmission = filter === filterId.transmission;
    const fuelType = filter === filterId.fuel;
    const calendar = filter === filterId.calendar;
    const distance = filter === filterId.distance;

    if (
        !cssClass
        || (!isMotorcycle && (model))
        || (isMotorcycle && (transmission || fuelType))
    ) return null;

    const displayValue = !isMotorcycle && f.filter.alternativeValues
        ? `${f.filter.alternativeValues[0].value}${f.filter.alternativeValues[0].name}`
        : isMotorcycle && f.filter.alternativeValues
            ? `${f.filter.alternativeValues[1].value}${f.filter.alternativeValues[1].name}`
            : (filterData.displayType === FILTER_DISPLAY_TYPE.mot ? getMotDateValue(f.val) : f.val);

    return (
        <div key={f.id} title={checkNested(f.filter, 'filter.name', '')}>
            <i className={`pp-landing ${cssClass}`} />
            {displayValue}
            {calendar && i18n.p__('Short', 'y')}
            {distance && i18n.p__('Short', 'km')}
        </div>
    );
});

export default CategoryFilters;
