import classNames from 'classnames';

export const getActionClassName = id => (
    classNames('pp-ico', {
        'pp-action_type_sell': id === 1,
        'pp-action_type_buy': id === 2,
        'pp-action_type_change': id === 3,
        'pp-action_type_hire': id === 4,
        'pp-action_type_rent': id === 5,
        'pp-action_type_gift': id === 6 || id === 29,
        'pp-action_type_find_job': id === 7,
        'pp-action_type_offer_job': id === 8,
        'pp-action_type_found': id === 9,
        'pp-action_type_lost': id === 10,
        'pp-home': id === 11,
        'pp-zcat_charity': id === 13,
        'pp-action_type_all': id === 'all'
    })
);
