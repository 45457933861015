import {
    ACTIVATE_THREAD,
    CLEAR_MESSAGING_STATE,
    FLUSH_ALL_LOTS,
    FLUSH_ALL_MESSAGES,
    FLUSH_ALL_THREADS,
    GET_ALL_MESSAGES,
    GET_MESSAGES,
    GET_MESSAGING_LOT_LIST,
    GET_NEW_MESSAGES_COUNT,
    GET_PARTICIPANT_INFO,
    PATCHING_MESSAGE,
    RESET_USER_STATE,
    UPDATE_LOT_THREADS,
    SET_THREAD_FROM_HASH, GET_NOTIFICATIONS, PATCH_NOTIFICATION
} from 'ACTIONS';
import forEach from 'lodash/forEach';
import omitBy from 'lodash/omitBy';

import { checkNested } from 'MODULES/checkNested';
import values from 'lodash/values';

const INITIAL_STATE = {
    lots: {
        personal: {
            unread: {},
            active: {}, // store published lots
            archive: {}, // store closed && blocked,
            lastThreadTimeStamp: null
        },
        company: {
            unread: {},
            active: {},
            archive: {},
            lastThreadTimeStamp: null
        },
        notifications: [],
        amount: {}
    },
    participants: {},
    companyMessages: -1,
    privateMessages: -1,
    notifications: -1,
    totalMessages: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_MESSAGING_LOT_LIST:
            let messagingObject = state.lots[action.owner] || {};
            forEach(action.payload, (d, key) => {
                messagingObject = omitBy(messagingObject, { itemId: d.itemId });
                messagingObject = { ...messagingObject, [key]: { ...checkNested(messagingObject, key, {}), ...d } };
            });
            let unreadMessages = { ...messagingObject.unread };
            let activeMessages = { ...messagingObject.active };
            let archiveMessages = { ...messagingObject.archive };

            // Process unread messages
            forEach(action.payloadUnread, (lot, timestamp) => {
                unreadMessages = {
                    ...unreadMessages,
                    [timestamp]: { ...checkNested(unreadMessages, timestamp, {}), ...lot }
                };
            });

            // Process active messages
            forEach(action.payloadActive, (lot, timestamp) => {
                activeMessages = {
                    ...activeMessages,
                    [timestamp]: { ...checkNested(unreadMessages, timestamp, {}), ...lot }
                };
            });

            forEach(action.payloadArchive, (lot, timestamp) => {
                archiveMessages = {
                    ...archiveMessages,
                    [timestamp]: { ...checkNested(archiveMessages, timestamp, {}), ...lot }
                };
            });

            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        messagingLoded: true,
                        ...state.lots[action.owner],
                        ...messagingObject,
                        unread: unreadMessages,
                        active: activeMessages,
                        archive: archiveMessages,
                        lastThreadTimeStamp: action.lastThreadTimeStamp

                    },
                    amount: {
                        ...state.lots.amount,
                        [action.owner]: action.amount
                    }
                }
            };
        case UPDATE_LOT_THREADS:
            const updatedAt = action.timestamp;
            const ownerState = state.lots[action.owner] || {};
            let threads = action.isUpdate ? [
                action.payload.uniqueHash,
                ...(checkNested(ownerState[action.status], [updatedAt, 'threads']) || [])
            ] : [
                ...(checkNested(ownerState[action.status], [updatedAt, 'threads']) || []),
                action.payload.uniqueHash
            ];
            if (checkNested(ownerState[action.status], [updatedAt, 'threads'], []).indexOf(action.payload.uniqueHash) !== -1) {
                threads = checkNested(ownerState[action.status], [updatedAt, 'threads'], []);
            }
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...ownerState,
                        [action.status]: {
                            ...state.lots[action.owner][action.status],
                            [updatedAt]: {
                                ...ownerState[action.status][updatedAt],
                                uniqueHash: action.payload.uniqueHash,
                                threads,
                                [action.payload.uniqueHash]: {
                                    ...(ownerState[action.status][updatedAt]?.[action.payload.uniqueHash] || {}),
                                    ...action.payload,
                                    updatedAt
                                },
                                lot: checkNested(action, 'lot') ? action.lot : state.lots[action.owner][action.status][updatedAt].lot
                            }
                        }
                    }
                }
            };
        case GET_PARTICIPANT_INFO:
            return ({
                ...state,
                participants: {
                    ...state.participants,
                    [`${action.participantType}_${action.payload.id}`]: action.payload
                }
            });
        case ACTIVATE_THREAD:
            return {
                ...state,
                thread: action.payload
            };
        case SET_THREAD_FROM_HASH:
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.status]: {
                            ...state.lots[action.owner][action.status],
                            [action.updatedAt]: action.payload
                        }
                    }
                }
            };
        case GET_MESSAGES:
            const msgPayload = values(action.payload);
            const actionId = checkNested(action, 'updatedAt', action.id, true);
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.status]: {
                            ...state.lots[action.owner][action.status],
                            [actionId]: {
                                ...state.lots[action.owner][action.status][actionId],
                                [action.hash]: checkNested(state.lots[action.owner][action.status], [actionId, action.hash]) ? {
                                    ...state.lots[action.owner][action.status][actionId][action.hash],
                                    messages: {
                                        ...state.lots[action.owner][action.status][actionId][action.hash].messages,
                                        ...action.payload
                                    },
                                    totalMessages: !action.incrementTotal ? state.lots[action.owner][action.status][actionId][action.hash].totalMessages : state.lots[action.owner][action.status][actionId][action.hash].totalMessages + 1,
                                    lastNotOwnedMessage: action.lastNotOwnedMessage || state.lots[action.owner][action.status][actionId][action.hash].lastNotOwnedMessage
                                } : {
                                    messages: {
                                        ...action.payload
                                    }
                                },
                                uniqueHash: action.hash,
                                updatedAt: msgPayload[0].createdAt
                            }
                        }
                    }
                }
            };
        case GET_NEW_MESSAGES_COUNT:
            return {
                ...state,
                unreadMessages: action.payload,
                companyMessages: action.company,
                privateMessages: action.user,
                notifications: action.notifications
            };
        case GET_ALL_MESSAGES:
            return ({
                ...state,
                totalMessages: action.payload
            });
        case PATCHING_MESSAGE:
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.status]: {
                            ...state.lots[action.owner][action.status],
                            [action.id]: {
                                ...state.lots[action.owner][action.status][action.id],
                                [action.hash]: {
                                    ...state.lots[action.owner][action.status][action.id][action.hash],
                                    ...state.lots[action.owner][action.status][action.id][action.hash].messages,
                                    newMessages: 0,
                                    messages: {
                                        ...state.lots[action.owner][action.status][action.id][action.hash].messages,
                                        [action.payload.id]: action.payload
                                    }
                                },
                                newMessages: state.lots[action.owner][action.status][action.id].newMessages - action.thread.newMessages
                            }
                        }
                    }
                },
                [`${action.owner}Messages`]: state[`${action.owner}Messages`] - 1,
                unreadMessages: state.unreadMessages - 1
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                lots: {
                    ...state.lots,
                    notifications: action.payload
                }
            };
        case PATCH_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications - 1,
                unreadMessages: state.unreadMessages - 1
            };
        case FLUSH_ALL_MESSAGES:
            return { ...state, messages: INITIAL_STATE.messages };
        case FLUSH_ALL_THREADS:
            return { ...state, threads: INITIAL_STATE.threads };
        case FLUSH_ALL_LOTS:
            return { ...state, lots: INITIAL_STATE.lots };
        case CLEAR_MESSAGING_STATE:
            return INITIAL_STATE;
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
