import {
    ACTIVATE_THREAD,
    GET_ALL_MESSAGES,
    GET_MESSAGES,
    INCREMENT_THREAD_COUNT,
    PATCHING_MESSAGE,
    POST_CONVERSATION_MESSAGE
} from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';
import { PARTICIPANT_TYPE, PARTICIPANT_TYPE_STRINGS } from 'CONST';
import actions from 'redux-form/es/actions';
import { apiPrefix } from 'ACTIONS/messaging/index';
import { getAllThreadsForItem } from 'ACTIONS/messaging/getAllThreadsForItem';
import { error } from 'ACTIONS/notifications/error';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { getUnixTimeStamp } from 'MODULES';

const { reset } = actions;

export const sendMessage = (participant, lot, thread, data, form, i18n, timestamp, status = 'active', updatedAt, history, pushHistory) => (dispatch) => {
    // FYI: send to participant type
    dispatch(setIsFetching(POST_CONVERSATION_MESSAGE, true));
    const promise = new Promise((res) => {
        if (thread) {
            res(thread);
        } else {
            dispatch(apiAction(
                {
                    url: API_URLS.THREADS_BY_ID(lot),
                    method: 'POST',
                    auth: true,
                    data: participant,
                    host: MESSAGING_API,
                    apiPrefix,
                    onSuccess: data => () => {
                        res(checkNested(data, 'content.uniqueHash'));
                        dispatch({
                            type: GET_ALL_MESSAGES,
                            payload: { [PARTICIPANT_TYPE_STRINGS[PARTICIPANT_TYPE.personal]]: 1 }
                        });
                        dispatch({ type: INCREMENT_THREAD_COUNT, lotId: lot });
                    },
                    label: PATCHING_MESSAGE
                }
            ));
        }
    });

    return promise.then((threadHash) => {
        dispatch(apiAction(
            {
                url: API_URLS.MESSAGES_BY_HASH(threadHash),
                method: 'POST',
                auth: true,
                data,
                host: MESSAGING_API,
                apiPrefix,
                onSuccess: payload => () => {
                    const messageResponse = checkNested(payload, ['content']);
                    const owner = participant === PARTICIPANT_TYPE.company ? 'company' : 'personal';
                    const response = {
                        [messageResponse.id]: messageResponse
                    };
                    const createTime = timestamp || updatedAt || getUnixTimeStamp();


                    dispatch({
                        type: GET_MESSAGES,
                        hash: threadHash,
                        payload: response,
                        owner,
                        updatedAt: createTime,
                        status,
                        incrementTotal: true
                    });
                    if (pushHistory) {
                        history.replace({ state: { thread: createTime, status } });
                    }
                    dispatch({ type: POST_CONVERSATION_MESSAGE, payload });
                    dispatch(reset(form));
                    dispatch({ type: ACTIVATE_THREAD, payload: threadHash });

                    if (!thread) {
                        dispatch(getAllThreadsForItem(lot, owner, 'DESC', updatedAt, updatedAt || createTime, status));
                    }
                },
                onFailure: e => () => {
                    dispatch(error(e, i18n));
                },
                label: POST_CONVERSATION_MESSAGE
            }
        ));
    });
};
