// TODO: why doesn't get called?
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MY_WALLET_DATA } from 'ACTIONS';
import { checkNested } from 'MODULES/checkNested';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';

export const getMyWallet = resolve => dispatch => {
    dispatch(setIsFetching(MY_WALLET_DATA, true));
    dispatch(apiAction({
        url: API_URLS.WALLET_DATA,
        method: 'GET',
        auth: true,
        onSuccess: (data) => {
            dispatch(setIsFetching(MY_WALLET_DATA, false));
            dispatch({
                type: MY_WALLET_DATA,
                payload: checkNested(data, 'content')
            });
            if (resolve) {
                resolve(checkNested(data, 'content'));
            }
        },
        onFailure: () => {

        },
        label: MY_WALLET_DATA
    }));
};
